import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { generateBreadcrumbLink } from './functions/breadcrumbLinkGeneration';

const BreadCrumb = ({ title, pageTitle }) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{title}</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item fw-bold text-main-color">
                                    {generateBreadcrumbLink(pageTitle, title) !== "" ?
                                        <Link to={generateBreadcrumbLink(pageTitle, title)}>
                                            {pageTitle}
                                        </Link>
                                        : <>{pageTitle}</>
                                    }
                                </li>
                                <li className="breadcrumb-item active text-main-color" >{title}</li>
                            </ol>
                        </div>

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;