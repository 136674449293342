import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Card, CardBody, Col, Container, Row,  Form } from "reactstrap";
import { useNavigate, Link, json } from 'react-router-dom';
import { Formik } from "formik";
import { addresource } from '../../../slices/resources/addresources/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { typevalue, Formvalues } from "./constants/Initialvalues";
import { LabelText } from "../../Utils/Input";
import { Loader } from '../../Utils/Loader';
import { getValidationSchema } from './constants/validationschema';
import CommonButton from '../../Utils/CommonButton';
import { PAGE_TITLES } from '../../Common/constants';
import { fetchAllUom } from '../../../slices/uom/thunk';
import formSchema from "../../Utils/schema.json";
import { RenderField } from "../../Utils/renderFields";
import { RenderErrorMessage } from "../../Utils/renderErrorMessages";
import { resOptionsAddition, resourceOptions, uomOptionsData } from './constants/selectoptions';
import { getAllResourcesById } from '../../../slices/resources/getresourcebyID/thunk';
import { customerIdData } from '../../Customers/constants/constantFunctionsView';
import { editResource } from '../../../slices/resources/updateresource/thunk';
export default function AddResources(initialAIResourceValues) {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [loading, setLoading] = useState('')
    const [isChecked, setIsChecked] = useState(false)
    const [isCurrency, setIsCurrency] = useState('')
    const [isUom, setIsUom] = useState('')
    const isEdit = location.pathname.includes('edit-resource')
    const isAiPath=location.pathname.includes('chatbot')

    let resourcetypevalue = typevalue;

    const initialValues = {
        isCurrency: '',
        name: "",
        resourceId: "",
        currencyCode: "",
        ceiling: "",
        floor: "",
        uom:'',
    };
    
    const validationSchema = getValidationSchema(isCurrency);
    const resourceName = customerIdData()
    const onSubmit = (values) => {
        const jsonObj = JSON.stringify({
            isCurrency: values?.isCurrency.value,
            name: values?.name,
            resourceId: values?.resourceId,
            ...(values?.isCurrency.value === true && {
                currencyCode: values?.currencyCode?.value
            }),
            ceiling: parseInt(values?.ceiling),
            floor: parseInt(values?.floor),
            unitofmeasurement: values?.uom?.value,
            override: isChecked,
        })
        // AddResources(jsonObj)
        if (!isEdit) {
            dispatch(addresource(jsonObj, setLoading, navigate))
        } else {
            dispatch(editResource(jsonObj, resourceName, setLoading, navigate));
        }
    }
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }
    // const AddResources = (jsonObj) => {
    //     dispatch(addresource(jsonObj, setLoading, navigate))
    // }
  
    useEffect(() => {
        dispatch(fetchAllUom(setLoading))
        dispatch(getAllResourcesById(setLoading,resourceName,initialValues))
    }, [])
    const uomData = useSelector((state) => state.Uom.uom)
    const resourcebyID = useSelector ((state)=>state.ResourceById.resourceById)
    const uomOptions = uomOptionsData(uomData)

    useEffect(()=>{
        if(isEdit){
            if(resourcebyID.override){
                setIsChecked(true)
            }
            else{
                setIsChecked(false)
            }
        }
    },[resourcebyID,isEdit])

    const resOption = resOptionsAddition(uomOptions)
    const pageTitleHeading = isEdit ? PAGE_TITLES.EDIT_RESOURCE : PAGE_TITLES.ADD_RESOURCE;

    return (
        <React.Fragment>
            <div id='csms' className={isAiPath?'':'page-content'}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={isAiPath?initialAIResourceValues.initialAIResourceValues:initialValues}
                    onSubmit={async (values) => {
                        if ((values.name !== "" && values.resourceId !== "" && values.ceiling !== "" && values.floor !== "")) {
                            onSubmit(values);
                        }
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldTouched, setTouched, isValid, dirty, resetForm }) => (
                        <Form className="needs-validation" onSubmit={handleSubmit}>
                            <Container fluid>
                                {isAiPath?null:
                                <BreadCrumb title={pageTitleHeading} pageTitle={PAGE_TITLES.RESOURCE_LIST} />}
                                <ToastContainer position='top-center' />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <div className="listingjs-table " id="customerList">
                                                    <CardBody className="card-body">
                                                        <div className="live-preview">
                                                            {formSchema.map((section, sectionIndex) => (
                                                                <Row className="gy-4 py-2" key={sectionIndex}>
                                                                    {section.title === "Resource" && section.sections.map((subSection, subSectionIndex) => (
                                                                        subSection.fields.filter(field => {
                                                                            if (field.name === "currencyCode" && values.isCurrency?.value === false) {
                                                                                setIsCurrency(values.isCurrency?.value)
                                                                                return false;
                                                                            }
                                                                            if (isEdit && (field.name === "isCurrency" || field.name === "name" || field.name ==="resourceId")) {
                                                                                field.disable = true
                                                                            }
                                                                            else {
                                                                                field.disable = false
                                                                            }
                                                                            if (field.name === "uom" && values.isCurrency?.value === true) {
                                                                                setIsCurrency(values.isCurrency?.value)
                                                                                return false
                                                                            }
                                                                            if (field.name === "resourceId") {
                                                                                if (values.isCurrency?.value === false) {
                                                                                    field.label = "Numeric Code";
                                                                                    field.placeholder = "Enter your Numeric Code";
                                                                                } else {
                                                                                    field.label = "Currency Number";
                                                                                    field.placeholder = "Enter your Currency Number";
                                                                                }
                                                                            }
                                                                            return true;
                                                                        }).map((field, fieldIndex) => (
                                                                            <>
                                                                                <Col xxl={6} md={6} key={`${subSectionIndex}-${fieldIndex}`}>
                                                                                    <LabelText
                                                                                        htmlFor={field.name}
                                                                                        className={field.required ? "text-danger" : ""}
                                                                                        displayText={field.label}
                                                                                        important={field.required ? "*" : ""}
                                                                                    />
                                                                                    {(field.secondLabel && values.isCurrency?.value === false) &&
                                                                                        <LabelText
                                                                                            displayText={field.secondLabel}
                                                                                            classLabelName={field.secondClassName}
                                                                                        />
                                                                                    }
                                                                                    <RenderField field={field}
                                                                                        handleChange={handleChange}
                                                                                        handleBlur={handleBlur}
                                                                                        values={values}
                                                                                        touched={touched}
                                                                                        errors={errors}
                                                                                        setFieldTouched={setFieldTouched}
                                                                                        setFieldValue={setFieldValue} options={resourceOptions} setIsChecked={setIsChecked} isChecked={isChecked} />
                                                                                    <RenderErrorMessage field={field} touched={touched} errors={errors} />
                                                                                </Col>
                                                                                {(field.name === "uom" && values.isCurrency === "") && <Col xxl={6} md={6}></Col>}
                                                                            </>
                                                                        ))
                                                                    ))}
                                                                </Row>
                                                            ))}
                                                        </div>
                                                    </CardBody>
                                                </div>
                                            </CardBody>
                                        </Card>
                                        <Row lg={6} className=" justify-content-end mb-4">
                                            <div className="live-preview">
                                                <div className="d-flex justify-content-end gap-2 ">
                                                    <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/resource')} buttonText={"Cancel"} />
                                                    <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} buttonAction={handleSubmit} />
                                                </div>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
                {loading && (<Loader />)}
            </div>
        </React.Fragment>
    );
}