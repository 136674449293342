import { Button } from "reactstrap"
import { handleExportData } from "../../Utils/ExportToCsv"
import { navigateToAddAttribute } from "./constants/AttributeJsonObj"

export const AttributeCustomButtons = ({ navigate, attributes,settingsIconRef, toggleSettingsModal }) => {
    return (
        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
            <div className="d-flex flex-column flex-md-row">
                <Button color="primary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(attributes) }}>
                    <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                </Button>
                <Button color="primary" className="btn-label me-2" onClick={() => navigateToAddAttribute(navigate)}>
                    <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Attribute
                </Button>
            </div>
            <div className="d-flex align-items-center">
                <div color="secondary" className="btn-icon me-2" ref={settingsIconRef} onClick={toggleSettingsModal}>
                    <i className="ri-settings-2-line fs-4"></i>
                </div>
            </div>
        </div>
    )
}