import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container, Form, FormFeedback, FormGroup } from 'reactstrap';
import BreadCrumb from '../Common/BreadCrumb';
import { Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { LabelText } from '../Utils/Input';
import CommonButton from '../Utils/CommonButton';
import InputField from '../Utils/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomer } from '../../slices/customer/fetchcustomer/thunk';
import { Loader } from '../Utils/Loader';
import { Formik } from 'formik';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { commonIDFunction, optionsForCustomerDetails } from '../Customers/constants/constantFunctions';
import { fetchCountries } from '../../slices/customer/countries/thunk';
import { fetchCities } from '../../slices/customer/cities/thunk';
import { fetchStates } from '../../slices/customer/states/thunk';
import SelectField from '../Utils/SelectField';
import { handleChangeForCity, handleChangeForCountry, handleChangeForState } from '../Customers/constants/HandleChangeFunctions';
import validationSchema from './Constant/ValidationSchema';
import { validatePhoneNumber } from '../Customers/constants/DocumentTableFunctions';
import { handleSubmitFunction } from './Constant/formUtil';
import ProfileForm from './ProfileForm';
import { setEditSuccessMessage } from '../../slices/toastMessage/action';
import { Navigate, useNavigate } from 'react-router-dom';

function LightWeightProfile() {
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState([])
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [state, setState] = useState(false)
    const [dialCode, setDialCode] = useState(" ")
    const [onfocus, setOnFocus] = useState(false)
    const dispatch = useDispatch();
    const navigate=useNavigate();
    const isEdit = location.pathname.includes("profile")
    const customerId = localStorage.getItem("customerId");
    const showEditSuccessMessage = useSelector(
        (state) => state.SuccessToast.showEditSuccessMessage
    );
    const permissions = useSelector((state) => state.permissions.permissions);
    const customerPermission = permissions.find((resource) => resource.resourceName === 'customer');
    useEffect(()=>{
        if (showEditSuccessMessage) {
            toast.success(`Customer Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
    })
    let optionCity = []
    let optionState = []
    const initialFormValues = {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        addressLine1: '',
        addressLine2: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
        dialCode: '',
        subscriptions: [],
        attributeList: []
    }
    let initialValues = initialFormValues

    const [subscriptionId, setSubscriptionId] = useState([])
    useEffect(() => {
        dispatch(fetchCustomer(setLoading, customerId, initialFormValues, customerPermission, setSubscriptionId, isEdit, setDialCode));
    }, [dispatch, customerId]);
    const callFetchCustomer = () => {
        dispatch(fetchCustomer(setLoading, customerId, initialFormValues, customerPermission, setSubscriptionId, isEdit, setDialCode));
    }
    const fetchCustomerData = useSelector(state => state.FetchCustomer.fetchCustomer);
    const [enableEdit, setEnableEdit] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        addressLine1: '',
        addressLine2: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
    });
    useEffect(() => {
        if (fetchCustomerData && fetchCustomerData?.customerInfo) {
            initialFormValues.firstName = fetchCustomerData?.customerInfo?.firstName,
                initialFormValues.email = fetchCustomerData?.customerInfo?.email,
                initialFormValues.lastName = fetchCustomerData?.customerInfo?.lastName,
                setDialCode((fetchCustomerData?.customerInfo?.dialCode).toString() + (fetchCustomerData?.customerInfo?.mobile).toString())
            initialFormValues.addressLine1 = fetchCustomerData?.customerInfo?.addressLine1,
                initialFormValues.addressLine2 = fetchCustomerData?.customerInfo?.addressLine2,
                initialFormValues.zipCode = fetchCustomerData?.customerInfo?.zipCode
            initialFormValues.country = { label: fetchCustomerData?.customerInfo?.country, value: fetchCustomerData?.customerInfo?.country }
            initialFormValues.state = { label: fetchCustomerData?.customerInfo?.state, value: fetchCustomerData?.customerInfo?.state }
            initialFormValues.city = { label: fetchCustomerData?.customerInfo?.city, value: fetchCustomerData?.customerInfo?.city }
            setFormData({
                firstName: fetchCustomerData?.customerInfo?.firstName,
                lastName: fetchCustomerData?.customerInfo?.lastName,
                email: fetchCustomerData?.customerInfo?.email,
                mobile: parseInt(fetchCustomerData?.customerInfo?.mobile),
                addressLine1: fetchCustomerData?.customerInfo?.addressLine1,
                addressLine2: fetchCustomerData?.customerInfo?.addressLine2,
                country: fetchCustomerData?.customerInfo?.country,
                dialCode: fetchCustomerData?.customerInfo?.dialCode,
                state: fetchCustomerData?.customerInfo?.state,
                city: fetchCustomerData?.customerInfo?.city,
                zipCode: fetchCustomerData?.customerInfo?.zipCode,
            });
        }
    }, [fetchCustomerData]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    useEffect(() => {
        dispatch(fetchCountries())
    }, [dispatch]);
    const countries = useSelector(state => state.Countries.countries)
    let countryID = commonIDFunction(countries?.data)
    const options = optionsForCustomerDetails(countryID)
    useEffect(() => {
        dispatch(fetchStates(countryID, isEdit, selectedCountry, optionState))
    }, [dispatch, isEdit, selectedCountry]);
    let statesData = useSelector(state => state.States.states)
    let stateID = commonIDFunction(statesData?.data?.states)
    optionState = optionsForCustomerDetails(stateID)
    useEffect(() => {
        dispatch(fetchCities(countryID, stateID, selectedCountry, selectedState, optionState))
    }, [dispatch, selectedCountry, selectedState]);
    let cityData = useSelector(state => state.Cities.cities)
    let cityID = cityData?.data?.map((city) => city) ?? [];
    optionCity = optionsForCustomerDetails(cityID)

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                handleSubmitFunction(values, dispatch, setLoading, customerId, setEnableEdit, dialCode,navigate);
                callFetchCustomer();
                setSubmitting(false);
            }} >
            {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldTouched, setFieldValue }) => (
                <Form className="needs-validation" onSubmit={handleSubmit}>
                    <div>
                        <div id='csms' className='page-content'>
                            <Container fluid>
                                {loading && <Loader />}
                                <BreadCrumb title="Profile" pageTitle="Customer" />
                                <ToastContainer position='top-center' />
                                <Row>
                                    <Col lg={12}>
                                        <div className="listingjs-table" id="customerList">
                                            <Card>
                                                <ProfileForm
                                                    fetchCustomerData={fetchCustomerData}
                                                    formData={formData}
                                                    enableEdit={enableEdit}
                                                    values={values}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    setDialCode={setDialCode}
                                                    setOnFocus={setOnFocus}
                                                    onfocus={onfocus}
                                                    dialCode={dialCode}
                                                    initialValues={initialValues}
                                                    options={options}
                                                    setSelectedCountry={setSelectedCountry}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                    setSelectedState={setSelectedState}
                                                    setSelectedCity={setSelectedCity}
                                                    setCities={setCities}
                                                    optionState={optionState}
                                                    optionCity={optionCity}
                                                    handleInputChange={handleInputChange}
                                                    validatePhoneNumber={validatePhoneNumber}
                                                />
                                                <Row lg={6} className=" justify-content-end mb-4">
                                                    {!enableEdit ? <div className="live-preview">
                                                        <div className="d-flex justify-content-end me-3">
                                                            <CommonButton color={"secondary"} buttonAction={() => {
                                                                setEnableEdit(true)
                                                            }} type={'button'} buttonText={"Edit"} />
                                                        </div>
                                                    </div>
                                                        :
                                                        <div className="live-preview">
                                                            <div className="d-flex justify-content-end me-3 gap-2">
                                                                <CommonButton color={"danger"} type="reset" buttonAction={() => { setEnableEdit(false) }} buttonText={"Cancel"} />
                                                                <CommonButton color={"secondary"} type={'submit'} buttonAction={() => {
                                                                    if (!onfocus && dialCode === "") {
                                                                        setOnFocus(true)                                                                      
                                                                    }
                                                                    
                                                                }} buttonText={"Save"} />
                                                            </div>
                                                        </div>
                                                    }
                                                </Row>
                                            </Card>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
export default LightWeightProfile;
