import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, FormGroup, CardHeader, Button, Nav, NavItem, NavLink, TabContent, TabPane, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import classnames from 'classnames';
import formSchema from '../Utils/schema.json';
import { RenderField } from '../Utils/renderFields';
import { RenderErrorMessage } from '../Utils/renderErrorMessages';
import { LabelText } from '../Utils/Input';
import { userOptions } from '../UserManagement/Constant/UserOptions';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { handleChangePassword, handleChangeUser } from '../UserManagement/Constant/JsonConstant';
import { tabChange } from "../Utils/SetDefaultRowData";
import CommonButton from '../Utils/CommonButton';

export default function ProviderDetail({
    profilePermission, values, handleChange, handleBlur, errors, touched, setFieldTouched, setFieldValue, user, userId, navigate, dispatch, setLoading, profile, setChangePasswordStatus, isProfile
}) {
    const [activeTab, setActiveTab] = useState('1');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [status, setStatus] = useState(user?.status || 'Active');
    const roleName = localStorage.getItem('roleName')
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const toggleModal = () => setModalOpen(!modalOpen);
    const [isEdit, setIsEdit] = useState(false);

    const toggleEdit = () => {
        setIsEdit(prevState => !prevState);
    };

    return (
        <div className="live-preview">
            <Card>
                <CardHeader className="p-3">
                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                        {profilePermission && <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "1" })}
                                onClick={() => tabChange("1", activeTab, setActiveTab)}
                            >
                                <i className="fas fa-home"></i>
                                Personal Detail
                            </NavLink>
                        </NavItem>}
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === (profilePermission ? "2" : "1") })}
                                onClick={() => tabChange(profilePermission ? "2" : "1", activeTab, setActiveTab)}
                            >
                                <i className="far fa-user"></i>
                                Change Password
                            </NavLink>
                        </NavItem>
                    </Nav>
                    {activeTab === '1' && profilePermission && profilePermission.permissionList.includes("update") && (
                        <>
                            <div className="position-absolute top-0 end-0 m-2 me-3 d-none d-sm-block">
                                <Button
                                    to="#"
                                    color="primary"
                                    className="main-color text-white fs-5 px-3"
                                    onClick={toggleEdit}
                                >
                                    {isEdit ? "Cancel" : "Edit"}
                                </Button>
                            </div>
                            <div className="d-block d-sm-none text-end mt-4">
                                <Button
                                    to="#"
                                    color="primary"
                                    className="main-color text-white fs-5 px-5"
                                    onClick={toggleEdit}
                                >
                                    {isEdit ? "Cancel" : "Edit"}
                                </Button>
                            </div>
                        </>
                    )}
                </CardHeader>

                <CardBody>
                    <TabContent activeTab={activeTab}>
                        {!isEdit ?
                            <TabPane tabId="1">
                                <Row className='gy-4'>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">First Name</p>
                                            <p>{user?.profile?.firstName}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">Last Name </p>
                                            <p>{user?.profile?.lastName}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">Phone Number</p>
                                            <p>{user?.dialCode}-{user?.phone} </p>
                                        </FormGroup>
                                    </Col>
                                    {roleName !== 'systemUser' ?
                                        <Col xs={12} md={6} lg={6}>
                                            <FormGroup className="ms-md-5">
                                                <p className=" fw-bolder">Email Address</p>
                                                <p>{user?.email}</p>
                                            </FormGroup>
                                        </Col> : null}
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">User Name</p>
                                            <p>{user?.userDetails?.userName || ''}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">User Role</p>
                                            <p>{user?.role}</p>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </TabPane> :
                            <TabPane tabId="1" >
                                {formSchema.map((section, sectionIndex) => (
                                    <React.Fragment key={sectionIndex}>
                                        {section.title === "User" && section.sections &&
                                            section.sections.map((subSection, subSectionIndex) => (
                                                subSection.sectionTitle !== "Password Change" && (
                                                    <CardBody key={subSectionIndex}>
                                                        <Row className='gy-3'>
                                                            {subSection.fields.filter(field => {
                                                                if (isEdit && (field.name === "username" || field.name === "email" || field.name==="userrole")) {
                                                                    field.disable = true;
                                                                } else {
                                                                    field.disable = false;
                                                                }
                                                                if (field.name === "status" && !isEdit) {
                                                                    return false;
                                                                }
                                                                if (field.name === "email" && roleName === "systemUser") {
                                                                    return false;
                                                                }

                                                                return true;
                                                            }).map((field, fieldIndex) => (
                                                                <Col
                                                                    xxl={field.name === "description" ? 12 : 6}
                                                                    md={field.name === "description" ? 12 : 6}
                                                                    lg={field.name === "description" ? 12 : 6}
                                                                    key={`${subSectionIndex}-${fieldIndex}`}
                                                                >
                                                                    <LabelText
                                                                        htmlFor={field.name}
                                                                        className={field.required ? "text-danger" : ""}
                                                                        displayText={field.label}
                                                                        important={field.required ? "*" : ""}
                                                                    />
                                                                    {field.type === 'phone' ? (
                                                                        <FormGroup>
                                                                            <PhoneInput
                                                                                international
                                                                                countryCallingCodeEditable={false}
                                                                                className={`form-control ${touched[field.name] && errors[field.name] ? 'error-input' : ''}`}
                                                                                placeholder={field.placeholder}
                                                                                value={values[field.name]}
                                                                                onChange={(value) => setFieldValue(field.name, value)}
                                                                                onBlur={() => setFieldTouched(field.name, true)}
                                                                                disabled={field?.autoload && field.isEdit}
                                                                            />
                                                                            {touched[field.name] && errors[field.name] && (
                                                                                <div className="text-danger validation-font-size">
                                                                                    {errors[field.name]}
                                                                                </div>
                                                                            )}
                                                                        </FormGroup>
                                                                    ) : (
                                                                        <>
                                                                            <RenderField
                                                                                field={field}
                                                                                handleChange={handleChange}
                                                                                handleBlur={handleBlur}
                                                                                values={values}
                                                                                touched={touched}
                                                                                errors={errors}
                                                                                setFieldTouched={setFieldTouched}
                                                                                setFieldValue={setFieldValue}
                                                                                options={userOptions}
                                                                            />
                                                                            <RenderErrorMessage
                                                                                field={field}
                                                                                touched={touched}
                                                                                errors={errors}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </CardBody>
                                                )
                                            ))}
                                    </React.Fragment>
                                ))}
                            </TabPane>}
                        <TabPane tabId="2">
                            {formSchema.map((section, sectionIndex) => (
                                <React.Fragment key={sectionIndex}>
                                    {section.title === "User" && section.sections &&
                                        section.sections.map((subSection, subSectionIndex) => (
                                            subSection.sectionTitle === "Password Change" && (
                                                <CardBody key={subSectionIndex} className='mb-4 '>
                                                    <Row className='gy-4 mb-5'>
                                                        {subSection.fields.map((field, fieldIndex) => (
                                                            <Col
                                                                xxl={6}
                                                                md={6}
                                                                lg={6}
                                                                key={`${subSectionIndex}-${fieldIndex}`}
                                                            >
                                                                <LabelText
                                                                    htmlFor={field.name}
                                                                    className={field.required ? "text-danger" : ""}
                                                                    displayText={field.label}
                                                                    important={field.required ? "*" : ""}
                                                                />
                                                                <RenderField
                                                                    field={field}
                                                                    handleChange={handleChange}
                                                                    handleBlur={handleBlur}
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    setFieldTouched={setFieldTouched}
                                                                    setFieldValue={setFieldValue}
                                                                    options={userOptions}
                                                                />
                                                                <RenderErrorMessage
                                                                    field={field}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                />
                                                            </Col>
                                                        ))}
                                                        <Col lg={12} className="d-flex justify-content-end mb-5 mt-3">
                                                            <CommonButton outline={true} className={"cancel-button-background me-3"} color={"main-color"} type={"reset"} buttonAction={() => navigate(roleName === 'systemUser' ? '/operator-listing' : '/dashboard')} buttonText={"Cancel"} />

                                                            <Button color="primary" onClick={() => { if (values.newPassword !== "" && values.confirmNewPassword !== "") { handleChangePassword(values, dispatch, setLoading, navigate, userId) } }}>
                                                                Confirm
                                                            </Button>
                                                        </Col>

                                                    </Row>
                                                </CardBody>
                                            )
                                        ))}
                                </React.Fragment>
                            ))}
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
            {isEdit && activeTab === "1" && (
                <Col lg={12} className="d-flex justify-content-end mt-3">
                    <CommonButton outline={true} className={"cancel-button-background me-3"} color={"main-color"} type={"reset"} buttonAction={() => {
                        if (roleName === 'systemUser') {
                            navigate('/operator-listing');
                        } else {
                            navigate('/dashboard');
                        }
                    }} buttonText={"Cancel"} />
                    <Button color="main-color" onClick={() => { if (values?.username !== "" && values.firstname !== "" && values?.userrole?.value !== "") { handleChangeUser(values, dispatch, setLoading, navigate, userId, isProfile) } }}>
                        Confirm
                    </Button>
                </Col>
            )}
        </div>
    );
}
