import CreatableSelect from 'react-select/creatable';

export const CreatableSelectField = ({name,options,isMulti,values,handleChange,placeholder,onKeyDown}) => {
    const customStyles = {
        control:(styles) => ({ ...styles, borderRadius: '0.5rem' }),
        option: (provided, state) => ({
          ...provided,
          color: state.isFocused ? "white" : provided.color,
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: "#0078A3",
          color: "white",
        }),
      };
    return(
        <CreatableSelect theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#015E7F",
              primary25: "#015E7F",
              primary50: "#015E7F",
              primary75: "#015E7F",
            },
          })} value={values} onKeyDown={onKeyDown} placeholder={placeholder} onChange={handleChange} styles={customStyles}  name={name} isMulti={isMulti} options={options} />
    )
}