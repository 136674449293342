import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

export const ResetPasswordExpiryBox = () => {
    const handleOkClick = () => {
        window.location.href = "/";
    };
    return (
        <Modal centered={true} isOpen={true} >
            <ModalHeader>
            Token Expired
            </ModalHeader>
            <ModalBody className="d-flex justify-content-start align-items-start">
            Reset Password Token Expired Please Generate new Token.
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <Button color="secondary" onClick={handleOkClick} >Login</Button>
            </ModalFooter>
        </Modal>
    )
}
