import { STASTICAL_API } from "../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor"
import {fetchGetStatisticDataFailure, fetchGetStatisticDataSuccess } from "./reducer"

export const getStatisticalData= (CustomerId,setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${STASTICAL_API}/${CustomerId}`, 'GET').then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchGetStatisticDataSuccess(response))
        }
        if (response.error) {
            setLoading(false)
            dispatch(fetchGetStatisticDataFailure(response.error.message))
        }
    })
}