import { Button } from "reactstrap";
import { navigateToCreateCustomer } from "./constants/ListingCustomerFunctions";
import { handleExportData } from "../Utils/ExportToCsv";

export const CustomerTableCustomTableButtons = ({exportData,customerPermission,navigate}) => {
    return (
        <div className="row align-items-center">
            <div className="col-md-auto mb-2">
                <Button
                    color="secondary"
                    className="btn-label me-2 w-100"
                    onClick={() => {
                        handleExportData(exportData);
                    }}
                >
                    <i className="ri-upload-line label-icon align-middle fs-16 me-2 "></i>
                    Export Data
                </Button>
            </div>
            {customerPermission && customerPermission.permissionList.includes('create') &&
                <div className="col-md-auto">
                    <Button
                        color="secondary"
                        className="btn-label me-2 w-100"
                        onClick={() => navigateToCreateCustomer(navigate)}
                        style={{ marginTop: '-6px' }}
                    >
                        <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>
                        New Customer
                    </Button>
                </div>
            }
        </div>
    )
}