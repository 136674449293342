import React from 'react';
import Chart from 'react-apexcharts';
const PieChart = ({ currentDue, creditLimit, width, height }) => {
    const options = {
        chart: {
            type: 'donut',
        },
        labels:['Credit Limit','Current Due'],
        colors: ['#D9D9D9', '#FD7545'],
        dataLabels: {
            enabled: false
        },
        grid:{
            padding:{
                right:50
            }
        },
        legend: {
            show: false,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            itemMargin: {
                horizontal: 15,
                vertical: 10,
            },
        },
        plotOptions: {
            pie: {
                startAngle: 0,
                endAngle: 360,
                expandOnClick: true,
                offsetX: 0,
                offsetY: 0,
                customScale: 1,
                dataLabels: {
                    offset: 0,
                    minAngleToShowLabel: 10,
                },
                donut: {
                    size: '60%',
                    background: 'transparent',
                    labels: {
                        show: false,
                    },
                },
            },
        },
    };
    const series = [creditLimit - currentDue, currentDue];
    return (
        <div className="pie-chart">
            <Chart options={options} series={series} type="donut" width={width} height={height} />
        </div>
    );
};
export default PieChart;
