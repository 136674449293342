import { fetchPartnerListFailure, fetchPartnerListSuccess } from "./reducer"

import { PARTNER_ALLLISTING_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const getAllPartnerListing = (setLoading, PartnerId, initialValues, setEditData) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${PARTNER_ALLLISTING_API}/${PartnerId}`, 'GET').then((response) => {
            if (!response.error) {
                setEditData(response)
                initialValues.businessName = response.businessName
                initialValues.type = { "value": response.type, "label": response.type }
                initialValues.email = response.emailAddress
                initialValues.mobile = response.mobile
                initialValues.Address = response.addressLine1
                initialValues.country = { "value": response.country, "label": response.country }
                initialValues.city = { "value": response.city, "label": response.city }
                initialValues.state = { "value": response.state, "label": response.state }
                initialValues.websiteLink = response.websiteHref
                initialValues.zipCode = response.zipcode
                initialValues.firstName = response.primaryContact?.firstName
                initialValues.lastName = response.primaryContact?.lastName
                initialValues.email1 = response.primaryContact?.emailAddress
                initialValues.mobile1 = response.primaryContact?.mobile
                initialValues.addressLine2 = response.addressLine2
                initialValues.attributeList = response.characteristic
                initialValues.id = response.primaryContact?.id
               
                dispatch(fetchPartnerListSuccess(response));
            }
            if(response.error){
                setLoading(false)
                dispatch(fetchPartnerListFailure(response.error.message));
            }
        })
    } catch (error) {
        setLoading(false);
        toast.error("An error occurred. Please try again later.");
       
    }
};

