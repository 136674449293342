import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Container, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../Common/BreadCrumb";
import { Loader } from "../Utils/Loader";
import CommonModal from "../Utils/CommonModal";
import { PolicyRowOptions } from "./PolicyRowOptions";
import { PolicyCustomButtons } from "./PolicyCustomButton";
import { PoliciesColoumn } from "./constants/PolicyColoumn";
import { getAllPolicies } from "../../slices/Policy/thunk";
import { deletePolicies } from "../../slices/Policy/DeletePolicy/thunk";
import { setEditSuccessMessage, setSuccessMessage } from "../../slices/toastMessage/action";

export default function PolicyListing() {
    const [loading, setLoading] = useState()
    const [messageForPopUp, setMessageForPopUp] = useState('')
    const [modal, setModal] = useState(false);
    const [policyName, setPolicyName] = useState()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const columns = PoliciesColoumn()
    const toggleModal = (id) => {
        setPolicyName(id)
        setModal(!modal);
        document.body.style.overflow = 'auto'
    };
    useEffect(() => {
        dispatch(getAllPolicies(setLoading));
    }, [dispatch]);
    const Policies=useSelector((state)=>state?.GetAllPolicies?.getAllPolicies);
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Policy Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
        if (showEditSuccessMessage) {
            toast.success(`Policy Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, dispatch])
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title="Policy List" pageTitle="Policy" />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Card>
                            <CardBody>
                                <Row className="mb-3 mt-1 table-responsive">
                                    {loading && (<Loader />)}
                                    {Policies ?
                                        <MaterialReactTable
                                            icons={{ DragHandleIcon: () => <i className="ri-drag-move-fill" />, }}
                                            muiTableHeadCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            muiTableBodyCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            renderTopToolbarCustomActions={({ }) => (
                                                <PolicyCustomButtons   Policies={Policies} navigate={navigate} />
                                            )}
                                            displayColumnDefOptions={{
                                                'mrt-row-actions': {
                                                    header: '',
                                                    enableHiding: true
                                                }
                                            }}
                                            initialState={{
                                                columnVisibility: {
                                                    policyId: false,
                                                },
                                            }}
                                            columns={columns}
                                            data={Policies}
                                            enableRowActions
                                            enableColumnOrdering
                                            enableColumnDragging
                                            enableDensityToggle={false}
                                            renderRowActionMenuItems={({ closeMenu, row }) => <PolicyRowOptions navigate={navigate} row={row} setMessageForPopUp={setMessageForPopUp} toggleModal={toggleModal} closeMenu={closeMenu} />}
                                        /> : null}
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
            <div> 
                <CommonModal open={modal} toggle={toggleModal} buttonText={"Yes, Delete It"} messageForPopUp={messageForPopUp} modalAction={() => {
                    dispatch(deletePolicies(policyName.original?.policyLabel, setLoading))
                    toggleModal()
                }} />
            </div>
        </React.Fragment>
    )
}