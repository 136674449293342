import React from "react";
import LightWeightDashboards from "../pages/LightWeightCustomer/LightWeightDashboards";
import MvnoDashboards from "../pages/MVNO/MvnoDashboard";
import Dashboard from "../pages/Dashboard/Dashboard";

const DashboardWrapper = () => {
const roleName = localStorage.getItem('roleName');

  if (roleName === "Customer") {
    return <LightWeightDashboards />;
  } else if (roleName === "telcoAdmin") {
    return <MvnoDashboards />;
  } else {
    return <Dashboard />;
  }
};

export default DashboardWrapper;
