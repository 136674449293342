import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, FormFeedback, FormGroup } from 'reactstrap';
import SelectField from '../Utils/SelectField';
import InputField from '../Utils/InputField';
import { LabelText } from '../Utils/Input';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { handleChangeForCity, handleChangeForCountry, handleChangeForState } from '../Customers/constants/HandleChangeFunctions';

const ProfileForm = ({
    fetchCustomerData,
    formData,
    enableEdit,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setDialCode,
    setOnFocus,
    onfocus,
    dialCode,
    validatePhoneNumber,
    initialValues,
    options,
    setSelectedCountry,
    setFieldValue,
    setFieldTouched,
    setSelectedState,
    setSelectedCity,
    setCities,
    optionState,
    optionCity,
    handleInputChange
}) => {
    return (
        <CardBody>
            <Row lg={12}>
                <Col lg={6}>
                    <Card className='h-100'>
                        <CardHeader className="d-flex align-items-center header-selfcare-profile ">
                            <LabelText displayText={"Personal Information"} classLabelName={"fs-5 mt-2"} />
                        </CardHeader>
                        <CardBody>
                            <Row lg={6}>
                                <Col lg={6} >
                                    <div className="container">
                                        <div className="row">
                                            <div className="row mb-3" >
                                                <div className="col fs-5"><LabelText displayText={"Customer Id : "} /></div>
                                                <div>{fetchCustomerData?.customerInfo?.customerId}</div>
                                            </div>
                                            <div className="row mb-3" >
                                                <div className="col fs-5"><LabelText displayText={"Firstname : "} /></div>
                                                {!enableEdit ? <div>{formData.firstName}</div> :
                                                    <><InputField name="firstName" value={values.firstName} handleChange={handleChange} handleBlur={handleBlur} invalid={touched.firstName && errors.firstName
                                                        ? true
                                                        : false} />
                                                        {touched.firstName &&
                                                            errors.firstName ? (
                                                            <FormFeedback type="invalid">
                                                                {errors.firstName}
                                                            </FormFeedback>
                                                        ) : null}</>
                                                }
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col fs-5" ><LabelText displayText={"Email : "} /></div>
                                                {!enableEdit ? <div>{formData.email}</div> : <><InputField name="email" value={values.email} handleChange={handleChange} handleBlur={handleBlur} invalid={touched.email && errors.email
                                                    ? true
                                                    : false} />
                                                    {touched.email &&
                                                        errors.email ? (
                                                        <FormFeedback type="invalid">
                                                            {errors.email}
                                                        </FormFeedback>
                                                    ) : null}
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} >
                                    <div className="container  ">
                                        <div className="row" >
                                            <div className="row mb-3">
                                                <div className="col fs-5 mt-5 "><LabelText displayText={"Lastname : "} /></div>
                                                {!enableEdit ? <div>{formData.lastName}</div> : <><InputField name="lastName" value={values.lastName} handleChange={handleChange} handleBlur={handleBlur}
                                                    invalid={touched.lastName && errors.lastName
                                                        ? true
                                                        : false} />
                                                    {touched.lastName &&
                                                        errors.lastName ? (
                                                        <FormFeedback type="invalid">
                                                            {errors.lastName}
                                                        </FormFeedback>
                                                    ) : null}</>}
                                            </div>
                                            <div className="row mb-3" >
                                                <div className="col fs-5"><LabelText displayText={"Contact No : "} /></div>
                                                {!enableEdit ? <div>{(formData?.dialCode || '' + formData?.mobile || '') || ''}</div> :
                                                    <> <PhoneInput international countryCallingCodeEditable={false} className={` ${onfocus && (dialCode && !isValidPhoneNumber(dialCode)) ? 'error-input ' : 'form-control'} ps-3`} placeholder={"Enter your Phone Number"}
                                                        onChange={setDialCode} onFocus={() => setOnFocus(true)} value={dialCode} />
                                                        {onfocus &&
                                                            (<div className='text-danger validation-font-size'>{validatePhoneNumber(dialCode)}</div>)
                                                        }</>}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card className='h-100'>
                        <CardHeader className="d-flex align-items-center header-selfcare-profile ">
                            <LabelText displayText={"Address"} classLabelName={"fs-5 mt-1"} />
                        </CardHeader>
                        <CardBody>
                            <Row lg={6}>
                                <Col lg={6} >
                                    <div className="container">
                                        <div className="row">
                                            <div className="row mb-3" >
                                                <div className="col fs-5"><LabelText displayText={"Address 1 : "} /></div>
                                                {!enableEdit ? <div>{formData.addressLine1}</div> : <><InputField name="addressLine1" value={values.addressLine1} handleChange={handleChange} handleBlur={handleBlur}
                                                    invalid={touched.addressLine1 && errors.addressLine1
                                                        ? true
                                                        : false} />{touched.addressLine1 &&
                                                            errors.addressLine1 ? (
                                                            <FormFeedback type="invalid">
                                                                {errors.addressLine1}
                                                            </FormFeedback>
                                                        ) : null}</>}
                                            </div>
                                            <div className="row mb-3" >
                                                <div className="col fs-5"><LabelText displayText={"Address 2 : "} /></div>
                                                {!enableEdit ? <div>{formData.addressLine2}</div> : <InputField name="addressLine2" value={values.addressLine2} handleChange={handleChange} />}
                                            </div>
                                            <div className="col fs-5" ><LabelText displayText={"Country : "} /></div>
                                            {!enableEdit ? <div>{formData.country}</div> : <div className="row mb-3">
                                                <SelectField className={(errors.country && touched.country) || false ? 'error-input' : ''}
                                                    value={initialValues.country ? options?.filter((country) => {
                                                        country.label === initialValues.country
                                                    })
                                                        : values.country}
                                                    placeholder={"Select your Country"}
                                                    handleChange={handleChangeForCountry(setSelectedCountry, setFieldValue, setFieldTouched, setSelectedState, setSelectedCity, setCities)}
                                                    isClearable={true}
                                                    options={[
                                                        ...options,
                                                    ]} />
                                                {touched.country && errors.country ? (
                                                    <p className="text-danger">
                                                        {errors.country}
                                                    </p>
                                                ) : null}
                                            </div>}
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} >
                                    <div className="container">
                                        <div className="row ">
                                            <div className="col fs-5"><LabelText displayText={"State : "} /></div>
                                            {!enableEdit ? <div>{formData.state}</div> : <div className="row ">
                                                <FormGroup>
                                                    <SelectField
                                                        className={(errors.state && touched.state) ? 'error-input' : ''}
                                                        value={initialValues.state ? optionState?.filter((state) => {
                                                            state.label === initialValues.state
                                                        }) : values.state}
                                                        placeholder="Select your State"
                                                        handleChange={handleChangeForState(setSelectedState, setFieldValue)}
                                                        options={[
                                                            ...optionState,
                                                        ]}
                                                        isClearable={true}
                                                        isDisabled={!values.country}
                                                    />
                                                    {touched.state && errors.state ? (
                                                        <p className="text-danger">
                                                            {errors.state}
                                                        </p>
                                                    ) : null}
                                                </FormGroup>
                                            </div>}
                                            {!enableEdit ? <div className="col fs-5 mt-3 "><LabelText displayText={"City : "} /></div> : <div className="col fs-5 "><LabelText displayText={"City : "} /></div>}
                                            {!enableEdit ? <div className=''>{formData.city}</div> : <div className="row mb-3">
                                                <SelectField
                                                    className={(errors.city && touched.city) || false ? 'error-input' : ''}
                                                    value={initialValues.city ? optionCity?.filter((city) => {
                                                        city.label === initialValues.city
                                                    })
                                                        : values.city}
                                                    placeholder="Select your City"
                                                    handleChange={handleChangeForCity(setSelectedCity, setFieldValue)}
                                                    handleBlur={handleBlur}
                                                    options={[
                                                        ...optionCity,
                                                    ]}
                                                    isDisabled={!values.state}
                                                    isClearable={true}
                                                />
                                                {touched.city && errors.city ? (
                                                    <p className="text-danger">
                                                        {errors.city}
                                                    </p>
                                                ) : null}
                                            </div>}
                                            <div className={`row mb-3 ${enableEdit ? '' : 'mt-3'}`}>
                                                <div className="col fs-5"><LabelText displayText={"Zipcode : "} /></div>
                                                {!enableEdit ? (
                                                    <div>{formData.zipCode}</div>
                                                ) : (
                                                    <>
                                                        <InputField className='mt-0' name="zipCode" value={values.zipCode} handleChange={handleChange} handleBlur={handleBlur} invalid={touched.zipCode && errors.zipCode ? true : false} />
                                                        {touched.zipCode && errors.zipCode ? (
                                                            <FormFeedback type="invalid">
                                                                {errors.zipCode}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </CardBody>
    );
};

export default ProfileForm;
