import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { getUserByName } from "../../slices/UserManagement/GetUserByName/thunk";
import { LabelText } from "../Utils/Input";
import logoLarge from '../../assets/images/users/user-dummy-img.jpg'
import { viewDoc } from "../../slices/customer/documentview/thunk";
import { resetDocumentViewState } from "../../slices/customer/documentview/reducer";
export default function ViewUser() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState();
    const isEdit = location.pathname.includes('view-user');
    const userId = customerIdData();
    const [profileImage, setProfileImage] = useState(logoLarge);
    const [editData, setEditData] = useState()
    const [profileData, setProfileData] = useState(false);

    const initialValues = {
        firstname: "",
        lastname: "",
        username: "",
        userrole: "",
        phone: "",
        email: "",
        status: ""
    };
    let base64ImageData = []


    useEffect(() => {

        if (isEdit) {

            dispatch(getUserByName(userId, setLoading, initialValues, setEditData));

        }
    }, [dispatch]);
    const user = useSelector((state) => state.GetAllUserByName.getUsersByName)
    const userDetails = [
        { label: 'User ID', value: userId || '' },
        { label: 'User Name', value: user?.userDetails?.userName || '' },
        { label: 'User Role', value: user?.role || '' },
        { label: 'Email Address', value: user?.email || '' },
        { label: 'Phone Number', value: `${user?.dialCode || ''}-${user?.phone || ''}` || '' }
    ];
    useEffect(() => {
        if (editData && editData?.userDetails?.userName) {
            let partnerOrCustomer = "User"
            dispatch(viewDoc(setLoading, userId, editData?.userDetails?.userName, partnerOrCustomer, setProfileData))
        }
    }, [dispatch, editData]);
    let ViewDocument = useSelector(state => state.ViewDocument.documentView)
    base64ImageData = ViewDocument?.fileData
    useEffect(() => {
        if (base64ImageData) {
            setProfileImage(base64ImageData)

        }
    }, [base64ImageData])
    useEffect(() => {
        if (!profileImage) {
            setProfileImage(null)
        }
    }, [dispatch, profileImage])


    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={"View User"} pageTitle={"Users"} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className='listingjs-table' id='customerList'>
                                <Card>
                                    <CardBody className='card-body'>
                                        <div className='live-preview'>
                                            <Row>
                                                <Col lg={3}>
                                                    <div className="text-center position-relative">
                                                        <div className="profile-user position-relative d-inline-block mx-auto mb-2">
                                                            <img
                                                                src={profileImage}
                                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                                alt="user-profile"
                                                            />
                                                        </div>
                                                        <div className="fs-4">
                                                            {user?.userDetails?.userName || ''}
                                                        </div>
                                                        <div className="mt-1">
                                                            <p className="set-status-user">
                                                                {user?.status === 'active' ? 'Active' : 'Inactive'}
                                                            </p>
                                                        </div>
                                                        <div className="custom-border-lg-end d-none d-lg-block me-5"></div>
                                                    </div>
                                                </Col>
                                                <Col lg={9} >
                                                    <Row className="py-3 gy-5">
                                                        {userDetails.map((detail, index) => (
                                                            <Col key={index} xs={12} md={6} lg={4}>
                                                                <FormGroup>
                                                                    <p className="fw-bolder">{detail.label}</p>
                                                                    <p>{detail.value}</p>
                                                                </FormGroup>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {loading && <Loader />}
            </div>
        </React.Fragment>
    );
}
