import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Col, Container, Form, Label, Row } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../Utils/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { validationSchemaForUser } from "../UserManagement/Constant/valiadationshema";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { createUser } from "../../slices/UserManagement/CreateUser/thunk";
import { updateUser } from "../../slices/UserManagement/UpdateUser/thunk";
import { getUserByName } from "../../slices/UserManagement/GetUserByName/thunk";
import { LabelText } from "../Utils/Input";
import ProviderDetail from "./ProviderDetail";
import { validationSchemaForProfile } from "./validationSchemaForProfile";

export default function CreateProvider({ profilePermission, isEdit, isProfile }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const changePassword = location?.state?.changePassword || false;
    const [changePasswordStatus, setChangePasswordStatus] = useState(false);
    const [loading, setLoading] = useState()
    const [editData, setEditData] = useState(false);
    // const isEdit = location.pathname.includes('edit-user')
    const profile = location.pathname.includes('profile-user')
    const userId = localStorage.getItem('userId')
    const initialValues = {
        firstname: "",
        lastname: "",
        userNames: "",
        userrole: "",
        phone: "",
        email: "",
        status: "",
        newPassword: "",
        confirmNewPassword: ""

    }
    useEffect(() => {

        dispatch(getUserByName(userId, setLoading, initialValues, setEditData))

    }, [dispatch])
    const user = useSelector((state) => state.GetAllUserByName.getUsersByName)
    return (
        <React.Fragment>
            <div id='csms' >
                <Formik
                    validationSchema={validationSchemaForProfile()}
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                        if (
                            values?.firstname !== "" && values?.lastname !== "" && values?.email !== "" && values?.usernames !== " " && values?.userrole !== " " && values?.phone !== "" && !changePassword
                        ) {
                            const phoneNumber = formatPhoneNumberIntl(values?.phone)
                            let dialCodeWithPlus = phoneNumber.match(/^\+\d+(\s|$)/)[0];
                            let restOfNumber = phoneNumber.slice(dialCodeWithPlus?.length);
                            let mobileNumberWithoutSpaces = restOfNumber.replace(/\s/g, '');
                            let dialCodeWithoutSpaces = dialCodeWithPlus.replace(/\s/g, '');
                            const formattedValues = {
                                userName: values?.usernames,
                                role: values?.userrole?.value,
                                email: values?.email,
                                "profile": {
                                    firstName: values?.firstname,
                                    lastName: values?.lastname,
                                },
                                phone: mobileNumberWithoutSpaces,
                                dialCode: dialCodeWithoutSpaces,
                                status: values?.status?.value,
                            };
                            if (!isEdit) {
                                dispatch(createUser(formattedValues, setLoading, navigate));
                            } else {
                                dispatch(updateUser(setLoading, formattedValues, navigate, userId, false));
                            }
                        }
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldTouched }) => (
                        <Form className='needs-validation' onSubmit={handleSubmit}>
                            <Container fluid>
                                <ToastContainer position='top-center' />
                                <Row>
                                    {loading && (<Loader />)}
                                    <Col lg={12}>
                                        <div >
                                            {profilePermission &&
                                                <ProviderDetail profilePermission={profilePermission} values={values} errors={errors} touched={touched} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} handleBlur={handleBlur} handleChange={handleChange} isEdit={isEdit} user={user} userId={userId} navigate={navigate} dispatch={dispatch} setLoading={setLoading} changePassword={changePassword} profile={profile} setChangePasswordStatus={setChangePasswordStatus} isProfile={isProfile} />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    )
}