import { useState } from 'react';

const useFieldAttributes = () => {
  const [fieldAttributes, setFieldAttributes] = useState([]);
  const [selectedAttributeName, setSelectedAttributeName] = useState('');
  const isEmptyOrInvalidField = (index, values) => {
    const previousField = values?.attributeList[index];
    return !previousField || !previousField.name;
  };

  const handleSelectedAttributeName = (selectedAttributeName) => {
    setSelectedAttributeName(selectedAttributeName);
  };

  let dataAttribute = [...fieldAttributes];

  const handleAttributeChangeUpdate = (index, event) => {
    dataAttribute[index] = {
      ...dataAttribute[index],
      [event.target.name]: event.target.value,
    };
    setFieldAttributes(dataAttribute);
  };
  return {
    fieldAttributes,
    setFieldAttributes,
    selectedAttributeName,
    setSelectedAttributeName,
    isEmptyOrInvalidField,
    handleSelectedAttributeName,
    handleAttributeChangeUpdate,
  };
};

export default useFieldAttributes;
