import { MaterialReactTable } from "material-react-table"
import { taskColumns } from "./constants/taskColumns"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { MenuItem } from '@mui/material';
import { customerIdData } from "./constants/constantFunctionsView";
import { useEffect, useState } from "react";
import { DetailedTaskView } from "./DetailedTaskView";
import CommonButton from "../Utils/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatisticsOfTasks } from "../../slices/tasks/getstatistics/thunk";
import { Loader } from "../Utils/Loader";
import { fetchAllTasks } from "../../slices/tasks/getalltasks/thunk";

export const ViewTasks = ({ setView, setTaskDetails, view, taskPermission }) => {
    const [viewTask, setViewTask] = useState(false)
    const [viewGlobalTaskDetails, setViewGlobalTaskDetails] = useState(false)
    const [loading, setLoading] = useState(false)

    const taskPath = customerIdData()
    const dispatch = useDispatch()

    const mockData = [{
        taskId: "Tsk837377663",
        ...(taskPath === "tasks" && { customerId: "123" }),
        taskType: "Create Customer",
        internalStatus: "Success",
        provisioningStatus: "In Progress",
        taskTime: "20 Jun 2024, 08:00:00"
    }]

    const globalTask = {
        failedTasksMTD: 10,
        failedInternalTasksMTD: 4,
        failedProvisionalTasksMTD: 6,
        tasks: mockData
    }

    useEffect(() => {
        if (taskPath === "tasks") {
            taskPermission && dispatch(fetchStatisticsOfTasks(setLoading))
            taskPermission &&  dispatch(fetchAllTasks(setLoading))
        }
        else {
            taskPermission && dispatch(fetchAllTasks(setLoading, taskPath))
        }

    }, [dispatch])

    const statData = useSelector((state) => state.GetStatisticsOfTasks.getTaskStatistics)
    const taskData = useSelector((state) => state.GetAllTasks.getAllTasks)

    const columns = taskColumns(taskPath)

    return (

        <div className={taskPath === "tasks" && "page-content"}>
            {taskPath === "tasks" && <Row className="gy-4">
                <Col lg={12}>
                    {loading && <Loader />}
                    <Row>
                        <Col xxl={4}>
                            <Card className="border-for-task-card">
                                <CardBody>
                                    <div className="d-flex justify-content-center align-items-center p-3">
                                        <div className="flex-column text-center">
                                            <h4>Failed Tasks MTD</h4>
                                            <h1 className="mb-0">{statData[0]?.failedTasksMTD}</h1>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xxl={4}>
                            <Card className="border-for-task-card">
                                <CardBody>
                                    <div className="d-flex justify-content-center align-items-center p-3">
                                        <div className="flex-column text-center">
                                            <h4>Failed Internal Tasks MTD</h4>
                                            <h1 className="mb-0">{statData[0]?.failedInternalTasksMTD}</h1>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xxl={4}>
                            <Card className="border-for-task-card">
                                <CardBody>
                                    <div className="d-flex justify-content-center align-items-center p-3">
                                        <div className="flex-column text-center">
                                            <h4>Failed Provisional Tasks MTD</h4>
                                            <h1 className="mb-0">{statData[0]?.failedProvisionalTasksMTD}</h1>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>}
            <Card>
                <CardBody>
                    <Row className="table-responsive">
                        {((taskPath !== "tasks") || (taskPath === "tasks" && !viewTask)) && <MaterialReactTable data={taskData} columns={columns} enableDensityToggle={false}
                            enableColumnOrdering
                            manualFiltering
                            enableColumnDragging
                            enableRowActions
                            icons={{
                                DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                            }}
                            muiTableHeadCellProps={{
                                sx: {
                                    fontFamily: "Inter,sans-serif"
                                }
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    fontFamily: "Inter,sans-serif"
                                }
                            }}
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    header: '',
                                    enableHiding: true
                                },
                            }}
                            initialState={{
                                columnVisibility: {
                                    'taskId': false,
                                },
                            }}
                            renderRowActionMenuItems={({ closeMenu, row }) =>
                                [
                                    <MenuItem key="view" onClick={() => {
                                        if (taskPath !== "tasks") {
                                            setTaskDetails(row.original)
                                            setView(true)
                                        }
                                        else {
                                            setViewGlobalTaskDetails(row.original)
                                            setViewTask(true)
                                        }
                                        closeMenu()

                                    }}>
                                        <i className="ri-eye-line me-2"></i>
                                        View
                                    </MenuItem>
                                ]
                            }
                        />}
                    </Row>
                    {viewTask && <DetailedTaskView taskDetails={viewGlobalTaskDetails} />}
                </CardBody>
            </Card>
            {(viewTask) && <Row lg={6} className=" justify-content-end mb-4">
                <div className="d-flex justify-content-end gap-2 ">
                    <CommonButton color={"main-color"} type={'submit'} buttonAction={() => {
                        if (viewTask) {
                            setViewTask(false)
                        }
                    }} buttonText={"Back"} />
                </div>
            </Row>}
        </div>
    )
}