import { DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchPartnerDocumentUploadFailure, fetchPartnerDocumentUploadSuccess } from "./reducer";
import { setDocumentSuccessMessage, setEditSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { viewDoc } from "../../customer/documentview/thunk";

export const partnerUploadDocument = (jsonObj, setLoading, PartnerId, businessName, partnerOrCustomer, setFileSelected, navigate) => async (dispatch) => {
    setLoading(true)
    const roleName = localStorage.getItem('roleName');
    await determineBaseUrlAndPerformApiCall(`${DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API}`, 'POST', jsonObj).then(async (response) => {
        if (!response.error) {
            setLoading(false)
            setFileSelected(false)
            dispatch(fetchPartnerDocumentUploadSuccess(response))
            dispatch(viewDoc(setLoading, PartnerId, businessName, partnerOrCustomer))
            if (partnerOrCustomer === "User") {
                navigate("/user")
                dispatch(setEditSuccessMessage(true))
            }
            else if (partnerOrCustomer === "Operator" && roleName === "systemUser") {
                navigate('/operator-listing')
                dispatch(setEditSuccessMessage(true))
            }
            else if(partnerOrCustomer==="Partner"){
                navigate('/partner-listing')
                dispatch(setEditSuccessMessage(true))
            }
            else {
                toast.success(
                    'Logo Uploaded Successfully'
                )
                dispatch(setDocumentSuccessMessage(true))
            }
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchPartnerDocumentUploadFailure(response.error.message))
        }
        setLoading(false)
    }).catch((error) => {
        setLoading(false);
        // toast.error("An error occurred. Please try again later.");
    })
}