import { fetchCreatePartnerSuccess, fetchCreatePartnerFailure } from "./reducer"
import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { PARTNER_CREATE_API } from "../../constants/constants";
import { setSuccessMessage } from "../../toastMessage/action";
import { uploadDocument } from "../../customer/documentupload/thunk";

export const createPartners = (jsonObj, setLoading, navigate, logoFile) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${PARTNER_CREATE_API}`, 'POST', jsonObj).then((response) => {

        if (!response.error) {
            var formdata = new FormData();
            formdata.append("file", logoFile);
            formdata.append("ID", response?.id.split('/').pop());
            formdata.append("documentName", `logo`);
            formdata.append("documentType", "logo");
            formdata.append("uploadedBy", `${response?.businessName}`);
            dispatch(uploadDocument(formdata, setLoading, response?.id.split('/').pop()))
            setLoading(false)
            dispatch(fetchCreatePartnerSuccess(response))
            navigate('/partner-listing')
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchCreatePartnerFailure(response.error.message))
        }
    }).catch((error) => {
        setLoading(false);
        toast.error("An error occurred. Please try again later.");

    })
}