import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { setSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { POLICY_API, USER_MANAGEMENT_API, USER_MANAGEMENT_CREATE_API } from "../../constants/constants";
import { fetchCreateUserFailure, fetchCreateUserSucess } from "./reducer";
import { uploadDocument } from "../../customer/documentupload/thunk";

export const createUser = (jsonObj, setLoading, navigate, file) => (dispatch) => {
    setLoading(true)
    const roleName = localStorage.getItem("roleName")
    determineBaseUrlAndPerformApiCall(`${USER_MANAGEMENT_CREATE_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCreateUserSucess(response))
            const formData = new FormData()
            formData.append("ID", response?.userDetails?.userId)
            if(roleName === "systemUser"){
                formData.append("userType","operator")
            }
            formData.append("file", file);
            formData.append("documentName", response?.userDetails?.userName);
            formData.append("documentType", "logo");
            formData.append('uploadedBy', response?.userDetails?.userName)
            if (file) {
                dispatch(uploadDocument(formData, setLoading, response?.userDetails?.userId, "User", navigate))
            }
            else {
                navigate("/user")
                dispatch(setSuccessMessage(true))
            }
        }
        if (response.error) {
            dispatch(fetchCreateUserFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}