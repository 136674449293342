import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { getUserByName } from "../../slices/UserManagement/GetUserByName/thunk";
import { LabelText } from "../Utils/Input";
import logoLarge from '../../assets/images/users/user-dummy-img.jpg'
import UserDetails from "../UserManagement/UserDetail";
import CreateEditUser from "../UserManagement/CreateEditUser";
import CreateProvider from "./CreateProvider";
import { partnerUploadDocument } from "../../slices/PartnerMangement/PartnerDocumentUpload/thunk";
import { viewDoc } from "../../slices/customer/documentview/thunk";
import { documentUpdateFile } from "../../slices/customer/documentupdatefile/thunk";

export default function UserProfile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const[update,setUpdate]=useState(false);
    const userId = localStorage.getItem('userId');
    const [newEditValue, setNewEditValue] = useState(false);
    const isProfile = location.pathname.includes('profile')
    const permissions = useSelector((state) => state.permissions.permissions);
    const profilePermission = permissions.find((resource) => resource.resourceName === 'profileManagement');
    let base64ImageData = [];
    const initialValues = {
        firstname: "",
        lastname: "",
        usernames: "",
        userrole: "",
        phone: "",
        email: "",
        status: ""
    };

    const [profileImage, setProfileImage] = useState(logoLarge);
    const [fileSelected, setFileSelected] = useState(false);
    const[profileData,setProfileData]=useState(false);
const roleName=localStorage.getItem("roleName")
    useEffect(() => {
        if (isEdit) {
            dispatch(getUserByName(userId, setLoading, initialValues));
        }
    }, [dispatch, isEdit]);

    const user = useSelector((state) => state.GetAllUserByName.getUsersByName);
    useEffect(() => {
        if (user && user?.userDetails?.userName) {
            const partnerOrCustomer ='User';
            dispatch(viewDoc(setLoading, userId, user.userDetails.userName, partnerOrCustomer,setProfileData));
        }
    }, [dispatch, user, userId]);

    const ViewDocument = useSelector((state) => state.ViewDocument.documentView);
    base64ImageData = ViewDocument?.fileData;
    useEffect(() => {
        if (base64ImageData) {
            setProfileImage(base64ImageData);
        }
    }, [base64ImageData]);
    
    const documentJson = useCallback((file) => {
        const formData = new FormData();
        if (user?.userDetails) {
            formData.append("ID", user.userDetails.userId || '');
            formData.append("file", file);
            if(roleName === "systemUser"){
                formData.append("userType","operator")
            }
            formData.append("documentName", user.userDetails.userName || '');
            formData.append("documentType", "logo");
            formData.append('uploadedBy', user.userDetails.userName || '');
        } else {
            console.error('User details not available');
        }
        return formData;
    }, [user]);

    const UpdateJson = useCallback((file) => {
        const formData = new FormData();
        if (user?.userDetails) {
            formData.append("ID", user.userDetails.userId || '');
            formData.append("file", file);
            if(roleName === "systemUser"){
                formData.append("userType","operator")
            }
            formData.append("documentName", user.userDetails.userName || '');
            formData.append("documentType", "logo");
            formData.append("newDocumentName", `${user.userDetails.userName}`);
            formData.append('uploadedBy', user.userDetails.userName || '');
        } else {
            console.error('User details not available');
        }
        return formData;
    }, [user]);

    const uploadDocApiCall = useCallback((file) => {
        if (user?.userDetails) {
            const partnerOrCustomer = "User";
            if (base64ImageData) {
                const updateObj = UpdateJson(file);
                dispatch(documentUpdateFile(setLoading, updateObj, userId, user.userDetails.userName, partnerOrCustomer,setUpdate, setFileSelected,navigate));
            } else {
                const documentObj = documentJson(file);
                dispatch(partnerUploadDocument(documentObj, setLoading, userId, user.userDetails.userName, partnerOrCustomer, setFileSelected));
            }
        } else {
            console.error('User details not available for upload');
        }
    }, [dispatch, documentJson, UpdateJson, userId, user, base64ImageData]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileSelected(true);
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfileImage(e.target.result);
            };
            reader.readAsDataURL(file);
            uploadDocApiCall(file);
        }
    };

    const toggleEdit = () => {
        setIsEdit(prevState => !prevState);
    };

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={"Profile"} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col xxl={3}>
                            <Card className="">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                                            <img
                                                src={profileImage}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile"
                                            />
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input
                                                    id="profile-img-file-input"
                                                    type="file"
                                                    className="profile-img-file-input"
                                                    onChange={handleImageUpload}
                                                    accept="image/*"
                                                />
                                                <Label
                                                    htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xs"
                                                >
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        <h5 className="fs-16 mb-1">{user?.profile?.firstName} {user?.profile?.lastName}</h5>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card >
                                <CardBody className="mb-2">
                                    <div className="d-flex align-items-center mb-5">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Complete Your Profile</h5>
                                        </div>
                                        {/* <div className="flex-shrink-0">
                                            <Link to="#" className="badge bg-light text-secondary fs-12" onClick={toggleEdit}>
                                                <i className="ri-edit-box-line align-bottom me-1"></i>
                                                {isEdit ? "Cancel" : "Edit"}
                                            </Link>
                                        </div> */}
                                    </div>
                                    <div className="progress animated-progress custom-progress progress-label">
                                        
                                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: base64ImageData ? '100%' : '80%' }}
                                            aria-valuenow={base64ImageData ? '100' : '80'} aria-valuemin="0" aria-valuemax="100">
                                            <div className="label">{base64ImageData ? '100%' : '80%'}</div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={9}>
                            <div >
                                <CreateProvider profilePermission={profilePermission} isEdit={isEdit} isProfile={isProfile} />
                            </div>
                        </Col>
                    </Row>
                </Container>
                {loading && <Loader />}
            </div>
        </React.Fragment>
    );
}
