import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchDocumentDownloadSuccess, fetchDocumentDownloadFailure } from "./reducer";
import { toast } from "react-toastify";
import { setDocumentSuccessMessage } from "../../toastMessage/action";
import { DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API } from "../../constants/constants";

export const downloadDoc = (setLoading, customerId, documentName) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API}/${customerId}/${documentName}?attachment=download`, 'GET').then((response) => {
        if (!response.error) {
            setLoading(false)
            var a = document.createElement("a");
            a.href = response.fileData
            a.download = response.fileName; 
            a.click();
            dispatch(fetchDocumentDownloadSuccess(response))
            toast.success(
                'Document Downloaded Successfully'
            )
            dispatch(setDocumentSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchDocumentDownloadFailure(response.error.message))
        }
        setLoading(false)
    }).catch((error) => { toast.error(
        `${error.message}`
    ) })
}