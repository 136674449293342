import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, Col, Button } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { cancelOffer } from './constants/GrantsAndRecurringFunctions';
import { convertOfferFieldsForExporting } from './constants/exportOfferData';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllOffers } from '../../../slices/offer/thunk';
import OfferPreview from './OfferPreview';
import { ToastContainer, toast } from 'react-toastify';
import { handleExportData } from '../../Utils/ExportToCsv';
import { chargesColumnData, grantsColumnData, offerColumns, usageRatePlanColumnData } from './constants/ColumnsForOfferTable';
import { termValidity } from './constants/checkEmptyFields';
import { columnDataFormat, endDates, startDates } from './constants/displayInfinite';
import { tabChange } from '../../Utils/SetDefaultRowData';
import { Loader } from '../../Utils/Loader';
import { setEditSuccessMessage, setSuccessMessage, setDocumentSuccessMessage } from '../../../slices/toastMessage/action';
import { navigateToAddOffer } from './constants/GrantsAndRecurringFunctions';
import { OfferRowOptions } from './OfferRowOptions';
import CommonModal from '../../Utils/CommonModal';
import { PAGE_TITLES } from '../../Common/constants';
import classnames from 'classnames';

function OffersListings() {
    const [loading, setLoading] = useState(false);
    const [messageForPopUp, setMessageForPopUp] = useState('');
    const [activeTab, setActiveTab] = useState('0.1');
    const [activeTabForAddOn, setActiveTabForAddOn] = useState('1');
    const [index, setIndex] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [offerId, setOfferId] = useState('');
    const toggleModal = (id) => {
        setOfferId(id);
        setModal(!modal);
        document.body.style.overflow = 'auto';
    };
    useEffect(() => {
        dispatch(fetchAllOffers(setLoading));
    }, [dispatch]);

    const rolename = localStorage.getItem('roleName');
    const offers = useSelector((state) => state.Offer.offer);
    const permissions = useSelector((state) => state.permissions.permissions);
    const offerPermission = permissions.find((resource) => resource.resourceName === 'offer');
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage);
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
    const showDocumentSuccessMessage = useSelector((state) => state.SuccessToast.showDocumentSuccessMessage);
    const allEndDate = [];
    const allStartDate = [];
    const globalScope = [];
    const grant = [];
    const charge = [];
    const usage = [];

    allEndDate.push(...endDates(offers, []));
    allStartDate.push(...startDates(offers, []));
    columnDataFormat(offers, globalScope, grant, charge, usage);

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success('Offer Created Successfully');
            dispatch(setSuccessMessage(false));
        }
        if (showEditSuccessMessage) {
            toast.success('Offer Updated Successfully');
            dispatch(setEditSuccessMessage(false));
        }
        if (showDocumentSuccessMessage) {
            toast.success('Import Successful');
            dispatch(setDocumentSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, showDocumentSuccessMessage, dispatch]);

    const columns = offerColumns(grant, charge, usage, globalScope, allStartDate, allEndDate, rolename);
    const grantsColumn = grantsColumnData();
    const chargesColumn = chargesColumnData();
    const usageRatePlanColumn = usageRatePlanColumnData();
    const exportData = convertOfferFieldsForExporting(offers);

    const baseOffers = offers.filter(offer => !offer.addOnOffer);
    const addOnOffers = offers.filter(offer => offer.addOnOffer);

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.OFFER_LIST} pageTitle={PAGE_TITLES.PRICING} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="offerList">
                                <Card>
                                    <CardBody>
                                        <div className=' border-bottom'>
                                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0 " role="tablist">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTabForAddOn === "1" })}
                                                    onClick={() => tabChange("1", activeTabForAddOn, setActiveTabForAddOn)}>
                                                    <i className="fas fa-home"></i>
                                                    Base
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTabForAddOn === "2" })}
                                                    onClick={() => tabChange("2", activeTabForAddOn, setActiveTabForAddOn)}>
                                                    <i className="far fa-user"></i>
                                                    Add-On
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        </div>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {(offers && activeTabForAddOn) &&
                                                <MaterialReactTable
                                                    columns={columns}
                                                    data={activeTabForAddOn === "1" ? baseOffers : addOnOffers}
                                              
                                            icons={{
                                                DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                            }}
                                            muiTableHeadCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            muiTableBodyCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            muiTableDetailPanelProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            enableDensityToggle={false}
                                            renderTopToolbarCustomActions={({ }) => (
                                                <div className="d-flex flex-column flex-md-row align-items-md-center">
                                                    <Button color='secondary' className='btn-label me-2 mb-2 mb-md-0' onClick={() => navigate('/import-data')} ><i className='ri-download-line label-icon align-middle fs-16 me-2 ' ></i>Import Data</Button>
                                                    <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(exportData) }}> <i className="ri-upload-line label-icon align-middle fs-16 me-2 "></i> Export Data</Button>
                                                    {offerPermission && offerPermission.permissionList.includes('create') ?
                                                        <Button color="secondary" className="btn-label me-2" onClick={() => navigateToAddOffer(navigate)}><i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Offer</Button> : null}
                                                </div>
                                            )}
                                            enableColumnOrdering
                                            enableColumnDragging
                                            enableRowActions
                                            displayColumnDefOptions={{
                                                'mrt-row-actions': {
                                                    header: '',
                                                    enableHiding: true
                                                }
                                            }}
                                            initialState={{
                                                columnVisibility: {
                                                    'mrt-row-expand': false,
                                                }
                                            }}
                                            renderRowActionMenuItems={({ closeMenu, row }) => (
                                                <OfferRowOptions navigate={navigate} setMessageForPopUp={setMessageForPopUp} toggleModal={toggleModal} row={row} closeMenu={closeMenu} setActiveTab={setActiveTab} setIndex={setIndex} setLoading={setLoading} offerPermission={offerPermission} index={index} dispatch={dispatch} />
                                            )}
                                            renderDetailPanel={({ row }) => (
                                                <OfferPreview row={row} tabChange={tabChange} setActiveTab={setActiveTab} allStartDate={allStartDate} allEndDate={allEndDate} termValidity={termValidity} activeTab={activeTab} grantsColumn={grantsColumn} chargesColumn={chargesColumn} usageRatePlanColumn={usageRatePlanColumn} />
                                            )}
                                                />}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <CommonModal open={modal} toggle={toggleModal} buttonText={"Yes, Delete It"} messageForPopUp={messageForPopUp} modalAction={() => {
                    cancelOffer(dispatch, offerId.original.offerCode, setLoading);
                    toggleModal();
                }} />
            </div>
        </React.Fragment>
    );
}

export default OffersListings;