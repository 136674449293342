import { CUSTOMER_CHAT_AI } from "../constants/constants"
import { getSearchedCustomer } from "../customer/customerserach/thunk"
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor"
import { fetchgetCustomerAIFailure, fetchgetCustomerAISuccess } from "./reducer"
import { toast } from "react-toastify"

export const getCustomerAI = (setLoading, setGetTable, setErrorMessage, setSuccessMessage, jsonObj,setErrorForMessage,setErrorIcon,setDontDisplay) => async (dispatch) => {
    try {
        setLoading(true);
        setErrorMessage(null);
        setSuccessMessage(null);
        const response = await determineBaseUrlAndPerformApiCall(`${CUSTOMER_CHAT_AI}`, 'POST', jsonObj)
        if (response.status === 400) {
            setErrorMessage(response.text);
        }
        setLoading(false);
        dispatch(fetchgetCustomerAISuccess(response));
        // if (response.text.customerId) {
        //     setGetTable(true);
        // }
        // if (!response.text.customerId && !(response.status === 400)) {
        //     setSuccessMessage(response.text);
        // }
        if(response?.api_endpoint?.includes("/registerCustomer")){
            setDontDisplay(true)
        }
        if(response?.api_endpoint?.includes("/unitOfMeasurement")){
            setDontDisplay(true)
        }
        if(response?.api_endpoint?.includes("/resource")){
            setDontDisplay(true)
        }
        if (response?.text?.api_endpoint?.includes("/customers")) {
            setGetTable(true);
            let searchFields = [{
                "name": "MSISDN",
                "value": response?.text?.api_endpoint?.split('=')[1],
                "touched": false
            }]
            setLoading(false)
            dispatch(getSearchedCustomer(setLoading,response?.text?.api_endpoint?.split('=')[1],"","",setGetTable,setErrorForMessage,setErrorIcon,searchFields,true))
        }
        if (!response?.text?.api_endpoint?.includes("/customers") && !(response.status === 400)) {
            setSuccessMessage(response.text);
        }
    } catch (error) {
        setLoading(false);
        setGetTable(false);
        dispatch(fetchgetCustomerAIFailure(error.message));
        setErrorMessage(error.message);
    }
}