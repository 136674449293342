import { Card, Row, Col, Container, Progress, Accordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";
import { useEffect, useState } from "react";
import { Loader } from "../Utils/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import PieChart from "./PieChart";
import { fetchCustomerSummary } from "../../slices/customer/simulate/thunk";
import moment from "moment";
import { getStatisticalData } from "../../slices/customerDashboard/thunk";
import { usePlans } from "./Constant/dashboardUtil";
export default function LightWeightDashboard() {
    const [loading, setLoading] = useState()
    const [selectedOption, setSelectedOption] = useState("both");
    const [resources, setResources] = useState([]);
    const dispatch = useDispatch()
    const currentDue = 1500;
    const creditLimit = 5000;
    const id = {
        original: {
            CustomerId:localStorage.getItem("customerId")
        },
        toggleExpanded() { }
    }
    const plans = usePlans(setLoading);
    const [renderSimulate, setRenderSimulate] = useState()
    const simulateOrPreviewOrDashboard = "Dashboard"
    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const [open, setOpen] = useState('0');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    useEffect(() => {
        dispatch(fetchCustomerSummary(id, setLoading, simulateOrPreviewOrDashboard, setRenderSimulate))
        dispatch(getStatisticalData(id?.original?.CustomerId, setLoading));
    }, [])
    const fetchCustomerSummaryData = useSelector((state) => state?.Simulate?.simulate ?? null);
    const statiscalData = useSelector((state) => state?.GetStatisticDataForCustomer?.getStatisticData ?? null)
    useEffect(() => {
        const prepaidResources = fetchCustomerSummaryData?.resources?.filter(resource => resource?.type === "Prepaid");
        const postpaidResources = fetchCustomerSummaryData?.resources?.filter(resource => resource?.type === "Postpaid");
        setResources({ prepaid: prepaidResources, postpaid: postpaidResources });
    }, [fetchCustomerSummaryData]);
    return (
        <div className="page-content">
            <Container fluid>
                {loading && <Loader />}
                <ToastContainer position="top-center" />
                <Row id="csms" className="gy-4">
                    <Col xxl={4} md={12}>
                        <div>
                            <Card className="rounded-3  border border-warning" >
                                <div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex flex-column ps-3 pt-2">
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <div className="fs-5 invoice-text-color">Current Due: </div>
                                                    <div><span className="offer-status fw-bold fs-2 ">${currentDue}</span></div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <div className="fs-5 invoice-text-color">Credit Limit: </div>
                                                    <div><span className="fw-bold fs-5">${creditLimit}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <PieChart currentDue={currentDue} creditLimit={creditLimit} width={180} height={180} />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div>
                            <Card className=" rounded-3 border border-warning">
                                <div className="row mb-3 pt-4 ps-4">
                                    <div className="col-11">
                                        <div className="d-flex justify-content-between">
                                            <div className="fs-6 me-3 ms-1 invoice-text-color">Last Payment Date :</div>
                                            <div className="fs-13"><span>30 Nov 2024</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3 pb-2 ps-4">
                                    <div className="col-10">
                                        <div className="d-flex justify-content-between ms-1">
                                            <div className="fs-13 invoice-text-color">Last Payment Amount :</div>
                                            <div className="fs-13"><span>$ 3423</span></div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <div className="d-flex justify-content-between align-items-center mb-4 ">
                                <div className="d-flex flex-row ps-3 pt-2">
                                        <div className="form-check form-check-inline me-4">
                                            <input className="form-check-input offer-status" type="radio" name="paymentType" id="prepaid" value="prepaid" checked={selectedOption === "prepaid"} onChange={handleRadioChange} />
                                            <label className="form-check-label" htmlFor="prepaid">Prepaid</label>
                                        </div>
                                        <div className="form-check form-check-inline ms-4">
                                            <input className="form-check-input" type="radio" name="paymentType" id="postpaid" value="postpaid" checked={selectedOption === "postpaid"} onChange={handleRadioChange} />
                                            <label className="form-check-label" htmlFor="postpaid">Postpaid</label>
                                        </div> 
                                        <div className="form-check form-check-inline ms-4 mb-3  ">
                                            <input className="form-check-input" type="radio" name="paymentType" id="both" value="both" checked={selectedOption === "both"} onChange={handleRadioChange} />
                                            <label className="form-check-label" htmlFor="both">Both</label>
                                        </div> 
                                </div>
                            </div>
                            {selectedOption === "prepaid" || selectedOption === "both" ? (
                                <Card className="rounded-3">
                                    {resources?.prepaid && resources?.prepaid?.length > 0 && (
                                        <div className="d-flex flex-column ps-3 pt-2 col-11">
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="fs-5 fw-bold">Offer9012</div>
                                                        <div className="ms-2 subscription-id">Subscription ID: <span>{resources?.prepaid[0]?.subscriptionId}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="fs-6 me-3 invoice-text-color">Subscribed on:</div>
                                                        <div className="fs-13"><span>{moment(resources?.prepaid[0]?.validFrom).format('DD MMM YYYY') || ''}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="fs-13 invoice-text-color">Expires on:</div>
                                                        <div className="fs-13"><span>{moment(resources?.prepaid[0]?.validTo).format('DD MMM YYYY')}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {resources.prepaid.map((resource, index) => (
                                                <div key={index}>
                                                    <div className="row mb-3">
                                                        <div className="col">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="fs-5 fw-bold">{resource?.resourceName}</div>
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-2">
                                                            {resource?.currencySymbol}{resource?.remainingBalance} left of {resource?.currencySymbol}{resource?.originalBalance}
                                                        </div>
                                                        <div>
                                                            <Progress max={resource?.originalBalance} value={resource?.remainingBalance}></Progress>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Card>
                            ) : null}
                            {selectedOption === "postpaid" || selectedOption === "both" ? (
                                <Card className=" rounded-3 ">
                                    {resources.postpaid && resources.postpaid.length > 0 && (
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex flex-column ps-3 pt-2 col-11">
                                                <div className="row mb-3">
                                                    <div className="col">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="fs-5 fw-bold col-6">{resources?.postpaid[0]?.offerCode} </div>
                                                            <div className="subscription-id col-8 ms-4 ">Subscription ID :<span> {resources.postpaid[0].subscriptionId}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="fs-6 me-3 invoice-text-color">Subscribed on:</div>
                                                            <div className="fs-13"><span>{resources?.postpaid[0]?.validFrom}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="fs-13 invoice-text-color">Expires on :</div>
                                                            <div className="fs-13"><span>{resources?.postpaid[0]?.validTo || ''}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="fs-13 invoice-text-color">Current Due :  </div>
                                                            <div className="fs-13 me-4 "><span>$ 2354</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Card>) : null}
                        </div>
                    </Col>
                    <Col xxl={4} md={12}>
                        <Card className="h-100">
                            <div className="row mb-3">
                                <div className="col-11">
                                    <div className="d-flex justify-content-between">
                                        <div className="fs-4 me-3 ms-3 mt-3 fw-bold">Popular Plans</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-11 ms-3 ">
                                    {plans && Array.isArray(plans) && plans.length > 0 ? (
                                        <Accordion open={open} toggle={toggle}>
                                            {plans.map((plan, index) => (
                                                <AccordionItem key={index}>
                                                    <AccordionHeader targetId={`${index}`} >
                                                        {plan.offerName}
                                                    </AccordionHeader>
                                                    <AccordionBody accordionId={`${index}`}>
                                                        <div className="row mb-3">
                                                            <div className="col-11">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="fs-5 me-3 fw-bold text-dark ">{plan?.currencySymbol}{plan?.amount} </div>
                                                                    <div className="fs-13 text-dark fw-bold "><span>{plan?.offerCode}</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="fs-6 me-3 ">Validity : <span className="fw-bold text-dark">{moment(plan?.validity?.from).format('DD MMM YYYY')} - {moment(plan?.validity?.to).format('DD MMM YYYY')}</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {plan.data ?
                                                            <div className="row mb-3">
                                                                <div className="col-11">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="fs-6 me-3 invoice-text-color">DATA:<span className="text-dark fw-bold"> {plan?.data}</span></div>
                                                                    </div>
                                                                </div>
                                                            </div> : null}
                                                    </AccordionBody>
                                                </AccordionItem>
                                            ))}
                                        </Accordion>
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center h-100 " >
                                            <div className="p-5 text-center">
                                                <h3 className="p-5">No Popular Plans available</h3>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xxl={4} md={12}>
                        <Card className="h-100 d-flex justify-content-center align-items-center">
                            <div className="p-2 text-center">
                                <h3>Placeholder</h3>
                                <h3>Marketing Content</h3>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}