import { fetchServiceFailure, fetchServiceStart, fetchServiceSuccess } from "./reducer"
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { SERVICE_LISTING_API } from "../constants/constants";

export const getAllService = (setLoading) => async (dispatch) => {
    dispatch(fetchServiceStart());
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${SERVICE_LISTING_API}`,'GET')
        setLoading(false)
        dispatch(fetchServiceSuccess(response.reverse()));
    } catch (error) {
        dispatch(fetchServiceFailure(error.message));
    }
};

