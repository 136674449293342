import { formatPhoneNumberIntl } from "react-phone-number-input";
import { updateCustomer } from "../../../slices/customer/update/thunk";

export const handleSubmitFunction = (values, dispatch, setLoading, customerId, setEnableEdit, dialCode,navigate) => {
    let phoneNumber = formatPhoneNumberIntl(dialCode)
    let dialCodeWithPlus = phoneNumber.match(/^\+\d+(\s|$)/)[0];
    let restOfNumber = phoneNumber.slice(dialCodeWithPlus.length);
    let mobileNumberWithoutSpaces = restOfNumber.replace(/\s/g, '');
    let dialCodeWithoutSpaces = dialCodeWithPlus.replace(/\s/g, '');
    const jsonData = {
        customerId: customerId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: '',
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city.value,
        country: values.country.value,
        dialCode: dialCodeWithoutSpaces,
        mobile: parseInt(mobileNumberWithoutSpaces),
        state: values.state.value,
        zipCode: +values.zipCode
    };
    dispatch(updateCustomer(jsonData, customerId, setLoading,navigate))
    setEnableEdit(false);
};
