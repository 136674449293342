import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Col, Container, Form, Label, Row } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../Utils/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import UserDetails from "./UserDetail";
import { validationSchema, validationSchemaForUser } from "./Constant/valiadationshema";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { createUser } from "../../slices/UserManagement/CreateUser/thunk";
import { updateUser } from "../../slices/UserManagement/UpdateUser/thunk";
import { getUserByName } from "../../slices/UserManagement/GetUserByName/thunk";
import { LabelText } from "../Utils/Input";
import { documentJson, documentUpdateJson, handleChangePassword } from "./Constant/JsonConstant";
import { viewDoc } from "../../slices/customer/documentview/thunk";
import { documentUpdateFile } from "../../slices/customer/documentupdatefile/thunk";
import { partnerUploadDocument } from "../../slices/PartnerMangement/PartnerDocumentUpload/thunk";
import { resetDocumentViewState } from "../../slices/customer/documentview/reducer";

export default function CreateEditUser() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const changePassword = location?.state?.changePassword || false;
    const [loading, setLoading] = useState()
    const [logoFile, setLogoFile] = useState(null);
    const [imageOn, setImageOn] = useState(false);
    const [editData, setEditData] = useState(null);
    const [fileSelected, setFileSelected] = useState(false);
    const [profileData, setProfileData] = useState(false)
    const isEdit = location.pathname.includes('edit-user')
    const userId = customerIdData()
    const initialValues = {
        firstname: "",
        lastname: "",
        username: "",
        userrole: "",
        phone: "",
        email: "",
        status: "",
        newPassword: "",
        confirmNewPassword: ""

    }
    useEffect(() => {
        if (isEdit) {
            dispatch(getUserByName(userId, setLoading, initialValues, setEditData))
        }
    }, [dispatch])
    const user = useSelector((state) => state.GetAllUserByName.getUsersByName)

    useEffect(() => {
        if (isEdit && editData && editData?.userDetails?.userName) {
            let partnerOrCustomer = "User"
            dispatch(viewDoc(setLoading, userId, editData?.userDetails?.userName, partnerOrCustomer, setProfileData));
        }
    }, [dispatch, isEdit, editData])

    let base64ImageData = []
    let profile = useSelector((state) => state.ViewDocument.documentView)
    const [update, setUpdate] = useState(!!profile);
    base64ImageData = profile?.fileData
    let documentName = profile?.documentName

    useEffect(() => {
        if (isEdit && base64ImageData) {
            setLogoFile(base64ImageData);
        }
        if (!profileData && isEdit) {
            setLogoFile(null)
        }
    }, [isEdit, base64ImageData,profileData]);

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Formik
                    validationSchema={validationSchemaForUser(changePassword)}
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                        if (
                            values?.firstname !== "" && values?.lastname !== "" && values?.email !== "" && values?.username !== " " && values?.userrole !== " " && values?.phone !== "" && !changePassword
                        ) {
                            const phoneNumber = formatPhoneNumberIntl(values?.phone)
                            let dialCodeWithPlus = phoneNumber.match(/^\+\d+(\s|$)/)[0];
                            let restOfNumber = phoneNumber.slice(dialCodeWithPlus?.length);
                            let mobileNumberWithoutSpaces = restOfNumber.replace(/\s/g, '');
                            let dialCodeWithoutSpaces = dialCodeWithPlus.replace(/\s/g, '');
                            const formattedValues = {
                                userName: values?.username,
                                role: values?.userrole?.value,
                                email: values?.email,
                                "profile": {
                                    firstName: values?.firstname,
                                    lastName: values?.lastname,
                                },
                                phone: mobileNumberWithoutSpaces,
                                dialCode: dialCodeWithoutSpaces,
                                status: values?.status?.value,
                            };
                            if (!isEdit) {
                                dispatch(createUser(formattedValues, setLoading, navigate, logoFile));
                            } else {
                                dispatch(updateUser(setLoading, formattedValues, navigate, userId,false, profile, update, setUpdate, fileSelected, logoFile, editData, setFileSelected));
                            }
                        }
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldTouched }) => (
                        <Form className='needs-validation' onSubmit={handleSubmit}>
                            <Container fluid>
                                <BreadCrumb title={changePassword ? 'Change Password' : (isEdit ? 'Edit User' : 'Add User')} pageTitle={!changePassword ? 'User List' : ''} />
                                <ToastContainer position='top-center' />
                                <Row>
                                    {loading && (<Loader />)}
                                    <Col lg={12}>
                                        <div className='listingjs-table ' id='userList'>

                                            <UserDetails profileData={profileData} documentName={documentName} setUpdate={setUpdate} setFileSelected={setFileSelected} profile={profile} imageOn={imageOn} setImageOn={setImageOn} logoFile={logoFile} setLogoFile={setLogoFile} values={values} errors={errors} touched={touched} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} handleBlur={handleBlur} handleChange={handleChange} isEdit={isEdit} user={user} userId={userId} navigate={navigate} dispatch={dispatch} setLoading={setLoading} changePassword={changePassword} />
                                            <Row lg={6} className=' justify-content-end mb-4'>
                                                <div className='live-preview'>
                                                    {!changePassword ?
                                                        <div className='d-flex justify-content-end gap-2 '>
                                                            <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate(changePassword ? '/dashboard' : '/user')} buttonText={"Cancel"} />
                                                            <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                                                        </div>
                                                        : null}
                                                </div>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    )
}