import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, CardHeader, Container, Input, Label, Table, Form } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Row, Col, FormGroup, FormFeedback } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, ErrorMessage, FieldArray } from 'formik';
import { fetchCountries } from '../../slices/customer/countries/thunk';
import { commonIDFunction, isEmptyOrInvalidField, optionsForCustomerDetails } from '../Customers/constants/constantFunctions';
import { fetchCities } from '../../slices/customer/cities/thunk';
import { fetchStates } from '../../slices/customer/states/thunk';
import { useDispatch, useSelector } from "react-redux";
import { createPartners } from "../../slices/PartnerMangement/CreatePartner/thunk";
import { Loader } from '../../Components/Utils/Loader';
import { getAllPartnerListing } from "../../slices/PartnerMangement/ListingPartner/thunk";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { editPartner } from "../../slices/PartnerMangement/EditPartner/thunk";
import { viewDoc } from "../../slices/customer/documentview/thunk";
import CommonButton from "../Utils/CommonButton";
import { validationSchema } from "./Constants/validationSchema";
import { jsonObj, editJsonObj } from "./Constants/PartnerUtils";
import BusinessProfileForm from "./BusinessProfile";
import PrimaryContactForm from "./PrimaryContact";
import { UserRoleOptions } from "./Constants/PartnerUtils";
import AttributeListForm from "./AttributeListForm";
import { PAGE_TITLES } from "../Common/constants";
import { handleRemoveLogo } from "./Constants/HandelRemoveLogo";
function CreatePartners() {
    const initialFormValues = {
        businessName: '',
        type: '',
        email: '',
        mobile: '',
        Address: '',
        country: '',
        city: '',
        state: '',
        websiteLink: '',
        zipCode: '',
        firstName: '',
        lastName: '',
        email1: '',
        mobile1: '',
        userName: '',
        userRole: '',
        addressLine2: '',
        attributeList: [],
        id: ''
    };
    let initialValues = initialFormValues;
    const dispatch = useDispatch()
    const [cities, setCities] = useState([])
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [enableFormReinitialize, setEnableFormReinitialize] = useState(false)
    const [loading, setLoading] = useState(false)
    const isEdit = location.pathname.includes('update-partner')
    const isCreate = location.pathname.includes('create-partner')
    const [logoFile, setLogoFile] = useState(null);
    const [editData, setEditData] = useState(null);
    const [imageOn, setImageOn] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [profileData, setProfileData] = useState(false)
    const fileInputRef = useRef(null);
    const navigate = useNavigate()
    const PartnerId = customerIdData()
    let optionCity = []
    let optionState = []
    let base64ImageData = []
    let partnerOrCustomer = ""
    useEffect(() => {
        if (isEdit && editData && editData?.businessName) {
            partnerOrCustomer = "Partner"
            dispatch(viewDoc(setLoading, PartnerId, "logo", partnerOrCustomer,setProfileData));
        }
    }, [dispatch, isEdit, editData]);
    useEffect(() => {
        dispatch(fetchCountries())
        if (isEdit) {
            dispatch(getAllPartnerListing(setLoading, PartnerId, initialValues, setEditData))
        }
    }, [dispatch]);
    const countries = useSelector(state => state.Countries.countries)
    let countryID = commonIDFunction(countries?.data)
    const options = optionsForCustomerDetails(countryID)
    let PartnerlistData = useSelector(state => state.PartnerList.partnerList)
    let ViewDocument = useSelector(state => state.ViewDocument.documentView)
    base64ImageData = ViewDocument?.fileData
    let documentName = ViewDocument?.documentName
    let profile = useSelector((state) => state.ViewDocument.documentView)
    const [update, setUpdate] = useState(!!profile);
    useEffect(() => {
        if (isEdit && base64ImageData) {
            setLogoFile(base64ImageData);
        }
    }, [isEdit, base64ImageData]);
    useEffect(() => {
        dispatch(fetchStates(countryID, isEdit, selectedCountry, optionState))
    }, [dispatch, isEdit, selectedCountry]);
    let statesData = useSelector(state => state.States.states)
    let stateID = commonIDFunction(statesData?.data?.states)
    optionState = optionsForCustomerDetails(stateID)
    useEffect(() => {
        dispatch(fetchCities(countryID, stateID, selectedCountry, selectedState, optionState))
    }, [dispatch, selectedCountry, selectedState]);
    let cityData = useSelector(state => state.Cities.cities)
    let cityID = cityData?.data?.map((city) => city) ?? [];
    optionCity = optionsForCustomerDetails(cityID)
    useEffect(() => {
        if (isCreate) {
            setEnableFormReinitialize(true)
            optionState = []
            optionCity = []
        }
    }, [isCreate, optionState, optionCity])
    const submitPartner = (values) => {
        if (isEdit) {
            let editObj = editJsonObj(values)
            dispatch(editPartner(editObj, setLoading, navigate, PartnerId,logoFile,profile,update,setUpdate,fileSelected,editData,setFileSelected))
        }
        else {
            let jsonObject = jsonObj(values)
            dispatch(createPartners(jsonObject, setLoading, navigate,logoFile))
        }
    }
    return (
        <React.Fragment>
            <Formik
                enableReinitialize={enableFormReinitialize}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values) => submitPartner(values, isEdit, dispatch, PartnerId, setLoading, navigate,logoFile)}
            >
                {({ handleSubmit, values, handleChange, errors, touched, handleBlur, setFieldValue, setFieldTouched, setTouched }) => (
                    <Form className="needs-validation" onSubmit={handleSubmit}>
                        <div id='csms' className='page-content'>
                            <Container fluid>
                                <BreadCrumb title={isEdit ? PAGE_TITLES.EDIT_PARTNER : PAGE_TITLES.ADD_PARTNER} pageTitle={PAGE_TITLES.PARTNER_LIST} />
                                <ToastContainer position='top-center' />
                                {loading && <Loader />}
                                <BusinessProfileForm
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    touched={touched} setFileSelected={setFileSelected}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    initialValues={initialValues}
                                    options={options}
                                    setSelectedCountry={setSelectedCountry}
                                    setFieldTouched={setFieldTouched}
                                    setSelectedState={setSelectedState}
                                    setSelectedCity={setSelectedCity}
                                    setCities={setCities}
                                    optionState={optionState}
                                    optionCity={optionCity}
                                    fileInputRef={fileInputRef}
                                    setImageOn={setImageOn}
                                    setLogoFile={setLogoFile}
                                    isCreate={isCreate}
                                    handleRemoveLogo={handleRemoveLogo}
                                    logoFile={logoFile}
                                    profile={profile}
                                    imageOn={imageOn}
                                    dispatch={dispatch}
                                    setLoading={setLoading}
                                    setUpdate={setUpdate}
                                    documentName={documentName}
                                    PartnerId={PartnerId}
                                    isEdit={isEdit}
                                />
                                <PrimaryContactForm
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    isEdit={isEdit}
                                    UserRoleOptions={UserRoleOptions}
                                />
                                <AttributeListForm
                                    values={values}
                                    handleChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    setTouched={setTouched}
                                />
                                <Row lg={6} className=" justify-content-end mb-4">
                                    <div className="live-preview">
                                        <div className="d-flex justify-content-end gap-2 ">
                                            <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/partner-listing')} buttonText={"Cancel"} />
                                            <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                    </Form>
                )} 
            </Formik>
        </React.Fragment>
    )
}
export default CreatePartners;