import { setSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { fetchCreateOperatorSucess, fetchCreateOperatorFailure } from "./reducer";
import { OPERATOR_CREATE_AND_EDIT_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { uploadDocument } from "../../customer/documentupload/thunk";

export const createOperator = (jsonObj, setLoading, navigate, file) => (dispatch) => {
    setLoading(true)
    const roleName = localStorage.getItem("roleName")
    determineBaseUrlAndPerformApiCall(`${OPERATOR_CREATE_AND_EDIT_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            const formData = new FormData()
            formData.append("ID", response?.operatorId)
            formData.append("file", file);
            if(roleName === "systemUser"){
                formData.append("userType","operator")
            }
            formData.append("documentName","logo");
            formData.append("documentType", "logo");
            formData.append('uploadedBy', response?.tradingName)
            if (file) { dispatch(uploadDocument(formData, setLoading, response?.id, "Operator", navigate)) }
            else {
                navigate('/operator-listing');
                dispatch(setSuccessMessage(true))
            }
            dispatch(fetchCreateOperatorSucess(response))
            
        }
        if (response.error) {
            dispatch(fetchCreateOperatorFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}
