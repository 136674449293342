import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createView } from '../../slices/View/CreateView/thunk';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import { CreatableSelectField } from './CreatableSelectField';

export const useColumnSettings = (pageName, columns) => {
    const [settingsModal, setSettingsModal] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState({});
    const [selectedView, setSelectedView] = useState('');
    const [viewColumns, setViewColumns] = useState(columns.map((col) => col.accessorKey));
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [loading, setLoading] = useState(false);

    const settingsIconRef = useRef(null);
    const dispatch = useDispatch();
    const view = useSelector((state) => state.View.getView);

    const toggleSettingsModal = () => {
        if (!settingsModal && settingsIconRef.current) {
            const rect = settingsIconRef.current.getBoundingClientRect();
            setModalPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
        }
        setSettingsModal((prevState) => !prevState);
    };

    const handleColumnVisibilityChange = (column) => {
        setVisibleColumns((prev) => ({
            ...prev,
            [column]: !prev[column],
        }));
    };

    const handleSaveSettings = () => {
        const visibleColumnsKeys = columns
            .filter(column => visibleColumns[column.accessorKey])
            .map(column => column.accessorKey);
        const saveData = {
            pagename: pageName,
            viewName: selectedView,
            columns: visibleColumnsKeys
        };
        dispatch(createView(saveData, setLoading));
        setViewColumns(saveData.columns)
        toggleSettingsModal();
    };

    const handleViewChange = (selectedOption) => {
        const selectedViewName = selectedOption?.value || '';
        setSelectedView(selectedViewName);
        const selectedViewData = view?.pages?.find((page) => page.pageName === pageName)?.views?.find((v) => v.viewName === selectedViewName);
        if (selectedViewData) {
            setViewColumns(selectedViewData.viewColumns);
            toggleSettingsModal();
        } else {
            setViewColumns(columns.map((col) => col.accessorKey));
        }
    };

    const SettingsModal = () => (
        <Modal isOpen={settingsModal} toggle={toggleSettingsModal} style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px`, position: 'absolute', margin: 0 }}>
            <ModalHeader toggle={toggleSettingsModal}>Settings</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="viewSelect">Select or Create View</Label>
                    <CreatableSelectField
                        name="viewSelect"
                        options={view?.pages?.find((page) => page.pageName === pageName)?.views?.map((v) => ({
                            value: v.viewName,
                            label: v.viewName,
                        })) || []}
                        isMulti={false}
                        value={selectedView ? { value: selectedView, label: selectedView } : null}
                        handleChange={handleViewChange}
                        placeholder="Select or create a view..."
                    />
                </FormGroup>
                {!view?.pages?.find((page) => page.pageName === pageName)?.views?.some((v) => v.viewName === selectedView) && selectedView && (
                    <FormGroup>
                        <Label>Columns Visibility</Label>
                        {columns.map((column) => (
                            <div key={column.accessorKey} className="form-check form-switch">
                                <Input
                                    type="switch"
                                    id={column.accessorKey}
                                    checked={visibleColumns[column.accessorKey]}
                                    onChange={() => handleColumnVisibilityChange(column.accessorKey)}
                                    className="form-check-input"
                                />
                                <Label for={column.accessorKey} className="form-check-label">
                                    {column.header}
                                </Label>
                            </div>
                        ))}
                    </FormGroup>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color={"main-color"} onClick={toggleSettingsModal}>Cancel</Button>
                <Button color="main-color" onClick={handleSaveSettings}>Save</Button>
            </ModalFooter>
        </Modal>
    );

    return {
        settingsModal,
        visibleColumns,
        selectedView,
        viewColumns,
        modalPosition,
        settingsIconRef,
        toggleSettingsModal,
        handleColumnVisibilityChange,
        handleSaveSettings,
        handleViewChange,
        SettingsModal
    };
};
