import { createAttribute } from "../../../../slices/attribute/createattribute/thunk";
import { updateAttribute } from "../../../../slices/attribute/updateattribute/thunk";

export const onSubmit = (dispatch, setLoading, navigate, isEdit, attribute, checked, mandatoryChecked) => (values) => {
    const jsonObj = AttributeJsonOnj(values, checked, mandatoryChecked);
    submitAttribute(jsonObj, dispatch, setLoading, navigate, isEdit, attribute);
};
export const submitAttribute = (jsonObj, dispatch, setLoading, navigate, isEdit, attribute) => {
    if (isEdit) {
        dispatch(updateAttribute(setLoading, attribute, jsonObj, navigate))
    }
    else {
        dispatch(createAttribute(jsonObj, setLoading, navigate));
    }
};

export const AttributeJsonOnj = (values, checked, mandatoryChecked) => {
    const valueArray = values?.attributeValue && values?.attributeValue?.map(item => item.value);
    const jsonObj = JSON.stringify({
        name: values?.name,
        description: values.description,
        type: values?.type.value,
        valueType: values?.valueType.value,
        default: values.defaultValue ? values.defaultValue.toString() : "0",
        minValue: values.minimumValue ? values.minimumValue.toString() : "0",
        maxValue: values.maximumValue ? values.maximumValue.toString() : "0",
        values: valueArray ? valueArray : [],
        searchable: checked,
        mandatory: mandatoryChecked
    })
    return jsonObj
}

export const navigateToAddAttribute = (navigate) => {
    navigate("/create-attribute")
}