import moment from "moment";
import { getCellStyle } from "../../Utils/DecideColor";
import { Box } from "@mui/material";

export const taskColumns = (taskPath) => {
    const columns = [
        {
            accessorKey: "taskId",
            header: "Task ID",
        },
        {
            accessorKey: "taskType",
            header: "Task Type",
        },
        {
            accessorKey: "internalStatus",
            header: "Internal Status",
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        ...getCellStyle(cell.getValue(),"Task"),
                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: "provisioningStatus",
            header: "Provisioning Status",
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        ...getCellStyle(cell.getValue(),"Task"),
                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: "taskTime",
            header: "Task Time",
            Cell: ({ cell }) => (
                <Box>
                    {moment(cell.getValue()).format("DD MMM YYYY, HH:MM:SS")}
                </Box>
            )
        }
    ];
    if (taskPath === 'tasks') {
        columns.splice(1, 0, {
            accessorKey: "customerId",
            header: "Customer ID",
        });
    }
    return columns;
};