import { MenuItem } from "@mui/material";
import { resetDocumentViewState } from "../../slices/customer/documentview/reducer";
import { fetchGetUserNameSucess } from "../../slices/UserManagement/GetUserByName/reducer";
import { resetPassword } from "../../slices/ResetPassword/thunk";
import { deactivateUser } from "../../slices/UserManagement/Deactivate/thunk";

export const UserRowOptions = ({ userPermission, location, dispatch, navigate, row, setMessageForPopUp, toggleModal, closeMenu, setUserName, setLoading }) => {
    const roleName = localStorage.getItem('roleName')
    return (
        [
            userPermission && userPermission.permissionList.includes('view') ? <MenuItem className='row-options' data-testid="update" key="view" onClick={() => { navigate(`/view-user/${row.original.userDetails.userId}`); dispatch(resetDocumentViewState()); dispatch(fetchGetUserNameSucess([])) }} >
                <i className='ri-account-circle-line me-2' /> View </MenuItem> : null,
            userPermission && userPermission.permissionList.includes('update') ? <MenuItem className='row-options' data-testid="update" key="update" onClick={() => { navigate(`/edit-user/${row.original.userDetails.userId}`) }} >
                <i className='bx bxs-edit me-2' /> Edit </MenuItem> : null,
            ((roleName === "systemUser" && row.original.role !== "systemUser") ||
            (roleName === "providerOperationUser" && (row.original.role !== "systemUser" || row.original.role !== "providerOperationUser")) ||
            (roleName === "telcoAdmin" && (row.original.role === "telcoOperationUser" || row.original.role === "telcoBusinessUser")) ||
               (roleName === "telcoOperationUser" && (row.original.role === "telcoBusinessUser")) ||
               (roleName === "operatorAdmin" && (row.original.role === "operatorOperationUser" || row.original.role === "operatorBusinessUser")) ||
               (roleName === "operatorOperationUser" && (row.original.role === "operatorBusinessUser")) ||
               (roleName === "genericAdmin" && (row.original.role === "genericOperationUser" || row.original.role === "genericBusinessUser")) ||
               (roleName === "genericOperationUser" && (row.original.role === "genericBusinessUser")) ||
               (roleName === "ISPOperatorAdmin" && (row.original.role === "ISPOperationUser" || row.original.role === "ISPBusinessUser")) ||
               (roleName === "ISPOperationUser" && (row.original.role === "ISPBusinessUser"))) ?
                <MenuItem className='row-options' data-testid="reset" key="reset" onClick={() => { dispatch(resetPassword(row.original.userDetails.userId, setLoading)) }} >
                    <i className='ri-account-circle-line me-2' /> Reset password </MenuItem> : null,
            ((userPermission && userPermission.permissionList.includes('deactivate')) &&
                ((roleName === "systemUser" && (row.original.role !== "telcoAdmin" && row.original.role !== "operatorAdmin" && row.original.role !== "genericAdmin" && row.original.role !== "ISPOperatorAdmin" && row.original.role !== "systemUser" && row.original.role !== "ISPPartnerAdmin")) ||
                    (roleName === "providerOperationUser" && (row.original.role !== "telcoAdmin" && row.original.role !== "operatorAdmin" && row.original.role !== "genericAdmin" && row.original.role !== "ISPOperatorAdmin" && row.original.role !== "systemUser" && row.original.role !== "ISPPartnerAdmin")) ||
                    (roleName === "telcoAdmin" && (row.original.role === "telcoOperationUser" || row.original.role === "telcoBusinessUser")) ||
                    (roleName === "telcoOperationUser" && (row.original.role === "telcoBusinessUser")) ||
                    (roleName === "operatorAdmin" && (row.original.role === "operatorOperationUser" || row.original.role === "operatorBusinessUser")) ||
                    (roleName === "operatorOperationUser" && (row.original.role === "operatorBusinessUser")) ||
                    (roleName === "genericAdmin" && (row.original.role === "genericOperationUser" || row.original.role === "genericBusinessUser")) ||
                    (roleName === "genericOperationUser" && (row.original.role === "genericBusinessUser")) ||
                    (roleName === "ISPOperatorAdmin" && (row.original.role === "ISPOperationUser" || row.original.role === "ISPBusinessUser")) ||
                    (roleName === "ISPOperationUser" && (row.original.role === "ISPBusinessUser")))) ?
                <MenuItem className='row-options' data-testid="deactivate" key="deactivate" onClick={() => {
                    dispatch(deactivateUser(row.original.userDetails.userId, setLoading, location))
                }}><i className='ri-close-circle-line me-2' /> Deactivate </MenuItem> : null,
            (userPermission && userPermission.permissionList.includes('delete'))
                ? <MenuItem className='row-options' key="delete" onClick={() => { setMessageForPopUp(`Are you sure you want to Delete this User "${row.original.userDetails.userId}"?`), toggleModal(row), closeMenu(), setUserName(row.original.userDetails.userId) }}>
                    <i className="ri ri-delete-bin-5-line me-2" />{" "}
                    Delete
                </MenuItem> : null,
        ]
    )
}