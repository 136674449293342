export const userOptions = (roleName) => {

    const roleOptions = roleName === "operatorAdmin" ? [
        { value: "operatorOperationUser", label: 'EV Operations User' }, { value: "operatorBusinessUser", label: 'EV Business User' },] 
    : roleName === "systemUser" ? [{ value: "providerOperationUser", label: 'Provider Operations User' },] 
    :roleName === "genericAdmin" ? [{ value: "genericOperationUser", label: 'Generic Operations User' },{ value: "genericBusinessUser", label: 'Generic Business User' }]
    :roleName === "ISPOperatorAdmin" ? [{ value: "ISPOperationUser", label: 'ISPOperator Operations User' },{ value: "ISPBusinessUser", label: 'ISPOperator Business User' }]
    : [ { value: "telcoOperationUser", label: 'Telco Operations User' }, { value: "telcoBusinessUser", label: 'Telco Business User' }];
    return [
        {
            name: "status",
            value: "User",
            options: [
                { value: "active", label: 'Active' },
                { value: "inactive", label: 'Inactive' },
            ]
        },
        {
            name: "userrole",
            value: "User",
            options: roleOptions
        },
    ];
}

// export const userOptions = [
//     {
//         name: "status",
//         value: "User",
//         options: [
//             { value: "active", label: 'Active' },
//             { value: "inactive", label: 'Inactive' },
//         ]
//     },
//     {
//         name: "userrole",
//         value: "User",
//         options: roleOptions
//     },
// ];
