import { toast } from "react-toastify";
import { setWelcomeToast } from "../slices/welcomeToaster/action";
import 'react-toastify/dist/ReactToastify.css';

export const handleLoginSuccess = (response, dispatch, setLoading, navigate) => {
  const { accessToken, refreshToken, userId, roleName, operatorId, permissions, userName, operatorName } = response;
  var jsonString = JSON.stringify(permissions);

  localStorage.setItem('token', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('userId', userId);
  localStorage.setItem('roleName', roleName);
  localStorage.setItem('operatorId', operatorId);
  localStorage.setItem('permissionss', jsonString);
  localStorage.setItem('userName', userName);
  localStorage.setItem('operatorName', operatorName);
  {roleName==='ISPPartnerAdmin'?
  localStorage.setItem('PartnerId', operatorId.split('/').pop()):null}




  if (
    response &&
    response.permissions &&
    Array.isArray(response.permissions)
  ) {
    const hasDashboardPermission = response.permissions.some(
      (permission) => permission.resourceName === 'dashboard'
    );
    const hasCustomerPermission = permissions.some(permission => permission.resourceName === 'customer');
    setLoading(false)
    if (hasDashboardPermission) {
      navigate('/dashboard');
    }
    else if (hasCustomerPermission) {
      navigate('/customer');
    } else {
      navigate('/operator-listing');
    }
  }
  dispatch(setWelcomeToast(true));
};

export const handleLoginFailure = (error, setLoading) => {
  setLoading(false);
  toast.error('Login failed. Username or Password is Incorrect');
};
