import { Container, Card, CardHeader, Nav, NavItem, NavLink, CardBody, TabContent, TabPane, Row } from "reactstrap";
import classnames from "classnames";
import React, { useEffect, useState } from 'react';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ViewSubscription from "./ViewSubscription";
import ViewReservation from "./ViewReservation";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomer } from "../../slices/customer/fetchcustomer/thunk";
import { fetchCustomerSummary } from "../../slices/customer/simulate/thunk";
import { CustomerProfile } from "./CustomerProfile";
import { customerIdData, subscriptionListData, subscriptionsaArrayData } from "./constants/constantFunctionsView";
import { formatDate } from "./constants/DateFormat";
import { tabChange } from "../Utils/SetDefaultRowData";
import { initialFormValues } from "./constants/constantValues";
import { noCurrencyArrayDataFormat, currencyDataFormat } from "./constants/constantFunctions";
import { Loader } from "../Utils/Loader";
import Invoice from "./Invoice"
import Bills from "./Bills"
import { fetchBillsForCustomer } from "../../slices/customer/getbills/thunk";
import { fetchAllInvoicesForCustomer } from "../../slices/invoices/invoiceforcustomer/thunk";
import { PAGE_TITLES } from "../Common/constants";
import { AttributeTableInView } from "./AttributeTableInView";
import { fetchAllOffers } from "../../slices/offer/thunk";
import { ViewTasks } from "./ViewTasks";
import { DetailedTaskView } from "./DetailedTaskView";
import CommonButton from "../Utils/CommonButton";

export default function CustomerView() {
    const [renderSimulate, setRenderSimulate] = useState(false)
    const [activeTab, setActiveTab] = useState("1");
    const [loading, setLoading] = useState(false)
    const [offer, setOffer] = useState([]);
    const [view, setView] = useState(false)
    const [taskDetails, setTaskDetails] = useState()

    const permissions = useSelector((state) => state.permissions.permissions);
    const billPermission = permissions.find((resource) => resource.resourceName === 'bill');
    const invoicePermission = permissions.find((resource) => resource.resourceName === 'invoice');
    const customerPermission = permissions.find((resource) => resource.resourceName === 'customer');
    const subscriptionPermission = permissions.find((resource) => resource.resourceName === 'subscription');
    const taskPermission = permissions.find((resource) => resource.resourceName === 'task');

    let simulateOrPreview = ""
    let noCurrencyArray = []
    let currencyArray = []

    const dispatch = useDispatch()

    const customerId = customerIdData()

    useEffect(() => {
        simulateOrPreview = "View"
        customerPermission && dispatch(fetchCustomer(setLoading, customerId, initialFormValues, customerPermission))
        customerPermission && dispatch(fetchCustomerSummary(customerId, setLoading, simulateOrPreview))
        billPermission && dispatch(fetchBillsForCustomer(customerId, setLoading))
        invoicePermission && dispatch(fetchAllInvoicesForCustomer(setLoading, customerId))
        dispatch(fetchAllOffers(setLoading))
    }, [])

    const customerData = useSelector((state) => state.FetchCustomer.fetchCustomer)
    const fetchCustomerSummaryData = useSelector(state => state.Simulate.simulate)
    const billData = useSelector((state) => state.GetBillsForCustomer.getBills)
    const invoiceData = useSelector((state) => state.InvoiceListForCustomer.invoiceListForCustomer)
    const offerData = useSelector((state) => state.Offer.offer)
    let attributeData = customerData?.customerInfo?.attributeList || [];

    const reservationPermission = permissions.find((resource) => resource.resourceName === 'reservation');

    noCurrencyArray = noCurrencyArrayDataFormat(fetchCustomerSummaryData, noCurrencyArray)
    currencyArray = currencyDataFormat(fetchCustomerSummaryData, currencyArray)

    let subscriptionList = []
    subscriptionList = subscriptionListData(customerData, subscriptionList)
    let subscriptionsArray = []
    subscriptionsArray = subscriptionsaArrayData(subscriptionsArray, subscriptionList)

    useEffect(() => {
        setOffer(subscriptionsArray)
    }, [subscriptionList])

    return (
        <div id="csms" className="page-content">
            <Container fluid>
                <BreadCrumb title={PAGE_TITLES.VIEW_CUSTOMER} pageTitle={PAGE_TITLES.CUSTOMER_LIST} />
                <Card>
                    <CardHeader>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                            role="tablist">
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "1" })}
                                    onClick={() => {
                                        tabChange("1", activeTab, setActiveTab);
                                    }}>
                                    <i className="fas fa-home"></i>
                                    Profile
                                </NavLink>
                            </NavItem>
                            {reservationPermission && reservationPermission.permissionList.includes("view") && <NavItem>
                                <NavLink to="#"
                                    className={classnames({ active: activeTab === "2" })}
                                    onClick={() => {
                                        tabChange("2", activeTab, setActiveTab);
                                    }}
                                    type="button">
                                    <i className="far fa-user"></i>
                                    Reservations
                                </NavLink>
                            </NavItem>}
                            {subscriptionPermission && subscriptionPermission.permissionList.includes("view") && <NavItem >
                                <NavLink to="#"
                                    className={classnames({ active: activeTab === (reservationPermission ? "3" : "2") })}
                                    onClick={() => {
                                        tabChange(reservationPermission ? "3" : "2", activeTab, setActiveTab);
                                    }}
                                    type="button">
                                    <i className="far fa-envelope"></i>
                                    Subscriptions
                                </NavLink>
                            </NavItem>}
                            {invoicePermission && invoicePermission.permissionList.includes("view") && <NavItem >
                                <NavLink to="#"
                                    className={classnames({ active: activeTab === (reservationPermission ? "4" : subscriptionPermission ? "3" : "2") })}
                                    onClick={() => {
                                        tabChange(reservationPermission ? "4" : subscriptionPermission ? "3" : "2", activeTab, setActiveTab);
                                    }}
                                    type="button">
                                    <i className="far fa-envelope"></i>
                                    Invoices
                                </NavLink>
                            </NavItem>}
                            {billPermission && billPermission.permissionList.includes("view") && <NavItem >
                                <NavLink to="#"
                                    className={classnames({ active: activeTab === (reservationPermission ? "5" : invoicePermission ? "4" : subscriptionPermission ? "3" : "2") })}
                                    onClick={() => {
                                        tabChange(reservationPermission ? "5" : invoicePermission ? "4" : subscriptionPermission ? "3" : "2", activeTab, setActiveTab);
                                    }}
                                    type="button">
                                    <i className="far fa-envelope"></i>
                                    Bills
                                </NavLink>
                            </NavItem>}
                            <NavItem>
                                <NavLink to="#"
                                    type="button"
                                    className={classnames({ active: activeTab === (billPermission ? "6" : reservationPermission ? "5" : invoicePermission ? "4" : subscriptionPermission ? "3" : "2") })}
                                    onClick={() => {
                                        tabChange(billPermission ? "6" : reservationPermission ? "5" : invoicePermission ? "4" : subscriptionPermission ? "3" : "2", activeTab, setActiveTab);
                                    }}
                                >
                                    Attributes
                                </NavLink>
                            </NavItem>
                            {taskPermission && taskPermission.permissionList.includes("view") && <NavItem>
                                <NavLink to="#"
                                    type="button"
                                    className={classnames({ active: activeTab === (taskPermission ? "7" : billPermission ? "6" : reservationPermission ? "5" : invoicePermission ? "4" : subscriptionPermission ? "3" : "2") })}
                                    onClick={() => {
                                        tabChange(taskPermission ? "7" : billPermission ? "6" : reservationPermission ? "5" : invoicePermission ? "4" : subscriptionPermission ? "3" : "2", activeTab, setActiveTab);
                                    }}
                                >
                                    Tasks
                                </NavLink>
                            </NavItem>}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1" >
                                <CustomerProfile customerPermission={customerPermission} customerData={customerData} noCurrencyArray={noCurrencyArray} currencyArray={currencyArray} fetchCustomerSummaryData={fetchCustomerSummaryData} formatDate={formatDate} offerData={offerData} />
                            </TabPane>
                            {reservationPermission && <TabPane tabId="2">
                                <ViewReservation />
                            </TabPane>}
                            {subscriptionPermission && <TabPane tabId={reservationPermission ? "3" : "2"}>
                                <ViewSubscription offer={offer} customerData={customerData} subscriptionPermission={subscriptionPermission} />
                            </TabPane>}
                            {invoicePermission && <TabPane tabId={reservationPermission ? "4" : subscriptionPermission ? "3" : "2"}>
                                <Invoice invoiceData={invoiceData} />
                            </TabPane>}
                            {billPermission && <TabPane tabId={reservationPermission ? "5" : invoicePermission ? "4" : subscriptionPermission ? "3" : "2"}>
                                <Bills billData={billData} />
                            </TabPane>}
                            <TabPane tabId={billPermission ? "6" : reservationPermission ? "5" : invoicePermission ? "4" : subscriptionPermission ? "3" : "2"}>
                                <AttributeTableInView attributeData={attributeData} />
                            </TabPane>
                            <TabPane tabId={taskPermission ? "7" : billPermission ? "6" : reservationPermission ? "5" : invoicePermission ? "4" : subscriptionPermission ? "3" : "2"}>
                                {!view ? <ViewTasks taskPermission={taskPermission} view={view} setTaskDetails={setTaskDetails} setView={setView} /> : <DetailedTaskView taskDetails={taskDetails} />}
                                {(view) && <Row lg={6} className=" justify-content-end mb-4">
                                    <div className="d-flex justify-content-end gap-2 ">
                                        <CommonButton color={"main-color"} type={'submit'} buttonAction={() => {
                                            if (view) {
                                                setView(false)
                                            }
                                        }} buttonText={"Back"} />
                                    </div>
                                </Row>}
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Container>
            {loading && (<Loader />)}
        </div>
    )
}