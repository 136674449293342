import { fetchCancelSubscriptionFailure, fetchCancelSubscriptionSuccess } from "./reducer";
import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { UNSUBSCRIBE_OFFER_API } from "../../constants/constants";

export const cancelSubscription = (jsonObj, setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${UNSUBSCRIBE_OFFER_API}`, 'POST', jsonObj).then((response) => {
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchCancelSubscriptionFailure(response.error.message))
        }
        if (!response.error) {
            dispatch(fetchCancelSubscriptionSuccess(response))
            setLoading(false)
            toast.success(
                `Subscription unsubscribed successfully!`,
            );
        }
    }).catch((errorResp) => {
    })
}