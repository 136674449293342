import * as Yup from 'yup';

export const validationSchemaForProfile = () => {
    let validationSchema = {}
    return validationSchema = Yup.object().shape({
    newPassword:Yup.string().required('kindly enter the new password').matches(/^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/,
    'Password must contain at least 8 characters, one uppercase letter, one number, and one special character'
    ),
  confirmNewPassword: Yup.string().required('kindly enter the confirm new password ').oneOf([Yup.ref('newPassword'), null], 'Passwords must match').matches( /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/,
      'Password must contain at least 8 characters, one uppercase letter, one number, and one special character'
    ),
})
}