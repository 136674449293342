import { MaterialReactTable } from "material-react-table";
import { MenuItem } from '@mui/material';
import { downloadDocApiCall, viewDocApiCall } from "./constants/HandleChangeFunctions";
import { UploadDocumentRowOptions } from "./UploadDocumentRowOptions";

export default function UploadDocumentTable ({setLoading,dispatch,customerId,setIsOpen,open,toggleModal,setFields,setEditingRow,setEditedDocumentName,setEditedDocumentType,deleteDocument,setButtonText,setMessageForPopUp,columns,docTableMockData}) {
    return(
        <MaterialReactTable
        displayColumnDefOptions={{
            'mrt-row-actions': {
                header: '',
                enableHiding: true
            },
        }}
        muiTableHeadCellProps={{
            sx: {
                fontFamily: "Inter,sans-serif"
            }
        }}
        muiTablePaperProps={{
            elevation: 0
        }}
        muiTableBodyCellProps={{
            sx: {
                fontFamily: "Inter,sans-serif",
                border: "none"
            }
        }}
        enableDensityToggle={false}
        renderRowActionMenuItems={({closeMenu,row }) => (
            <UploadDocumentRowOptions row={row} dispatch={dispatch} setLoading={setLoading} customerId={customerId} closeMenu={closeMenu} setFields={setFields} setEditingRow={setEditingRow} setEditedDocumentName={setEditedDocumentName} setEditedDocumentType={setEditedDocumentType} setButtonText={setButtonText} setMessageForPopUp={setMessageForPopUp} setIsOpen={setIsOpen} open={open}/>
        )}
        enableRowActions enableBottomToolbar={false} enableSorting={false} enableColumnActions={false} enablePagination={false} enableTopToolbar={false} data={docTableMockData} columns={columns} />
    )
}