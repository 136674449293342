import React from "react";
import { Card, CardHeader, CardBody, Row, Col, Label, FormGroup, } from "reactstrap";
import { LabelText } from "../Utils/Input";
import PieChart from "./constants/PieChart";
import { CustomerAddress, CustomerViewDetails } from "./CustomerViewDetail";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, } from "reactstrap";

export const CustomerProfile = ({
    customerData,
    fetchCustomerSummaryData,
    formatDate,
    customerPermission,
}) => {
    const groupedResources = fetchCustomerSummaryData?.resources?.reduce(
        (acc, resource) => {
            const key = `${resource.subscriptionId}-${resource.offerCode}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(resource);
            return acc;
        },
        {}
    );

    const renderGrantsCarousel = (resources) => {
        const [activeIndex, setActiveIndex] = React.useState(0);
        const [animating, setAnimating] = React.useState(false);
        const next = () => {
            if (animating) return;
            const nextIndex =
                activeIndex === resources.length - 1 ? 0 : activeIndex + 1;
            setActiveIndex(nextIndex);
        };
        const previous = () => {
            if (animating) return;
            const nextIndex =
                activeIndex === 0 ? resources.length - 1 : activeIndex - 1;
            setActiveIndex(nextIndex);
        };
        const goToIndex = (newIndex) => {
            if (animating) return;
            setActiveIndex(newIndex);
        };
        const slides = resources.map((resource) => {
            return (
                <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={resource.resourceName}
                >
                    {!resource.currencyCode ?
                        <Card className="border border-black customer-view-screen">
                            <CardHeader className="d-flex align-items-center customer-view-screen ">
                                <Label className="fs-5 mt-2">Grants</Label>
                            </CardHeader>
                            <CardBody className="px-5">

                                <div className="d-flex flex-column align-items-center flex-md-row">
                                    <div className="">
                                        <PieChart
                                            remainingBalance={resource.remainingBalance}
                                            originalBalance={resource.originalBalance}
                                            width={"100%"}
                                            height={200}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <p className="fw-bolder fs-5">{resource.resourceName}</p>
                                        </div>
                                        <div>
                                            <p>
                                                <b>{resource.remainingBalance}</b>{" "}left of{" "}<b>{resource.originalBalance}</b>
                                            </p>
                                            <p className='d-flex justify-content-start'>
                                                <LabelText displayText={`${formatDate(resource.validFrom)} - ${formatDate(resource.validTo)}`} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        : null}
                </CarouselItem>
            );
        });
        return (
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                interval={false}
            >
                <CarouselIndicators
                    items={resources}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />
                {slides}
                {activeIndex !== 0 && (
                    <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={previous}
                    >
                        <i className="ri-arrow-left-s-line"></i>
                    </CarouselControl>
                )}
                {activeIndex !== resources.length - 1 && (
                    <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={next}
                    >
                        <i className="ri-arrow-right-s-line"></i>
                    </CarouselControl>
                )}
            </Carousel>
        );
    };
    return (
        <Row lg={12}>
            <CustomerViewDetails
                customerData={customerData}
                customerPermission={customerPermission}
            />
            <CustomerAddress customerData={customerData} />
            <Col lg={12}>
                {groupedResources && <Card className="border border-black">
                    <CardHeader className="d-flex align-items-center">
                        <Label className="fs-5 mt-2">Balance</Label>
                    </CardHeader>
                    {groupedResources &&
                        Object.keys(groupedResources).map((key, index) => {
                            const resources = groupedResources[key];
                            return (
                                <CardBody key={index} className="card-body">
                                    <div className="live-preview">
                                        <Row className="gy-4">
                                            <Col xs={12} md={3}>
                                                <FormGroup>
                                                    <p className="fw-bolder">Subscription ID</p>
                                                    <p>{resources[0].subscriptionId}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <FormGroup>
                                                    <p className="fw-bolder">Offer Name</p>
                                                    <p>{resources[0].offerCode}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <FormGroup className="ms-md-5">
                                                    <p className="fw-bolder">Subscription Type</p>
                                                    <p className="main-color">Base</p>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={6}>
                                                {resources.length > 1 ? (
                                                    renderGrantsCarousel(resources)
                                                ) : resources[0].currencyCode ? null : (
                                                    <Card className="border border-black customer-view-screen">
                                                        <CardHeader className="d-flex align-items-center customer-view-screen">
                                                            <Label className="fs-5 mt-2">Grants</Label>
                                                        </CardHeader>
                                                        <CardBody className="px-5">
                                                            <div className="d-flex flex-column align-items-center flex-md-row">
                                                                <div className="">
                                                                    <PieChart
                                                                        remainingBalance={resources[0].remainingBalance}
                                                                        originalBalance={resources[0].originalBalance}
                                                                        width={"100%"}
                                                                        height={200}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <p className="fw-bolder fs-5">{resources[0].resourceName}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p>
                                                                            <b>{resources[0].remainingBalance}</b>{" "}left of{" "}<b>{resources[0].originalBalance}</b>
                                                                        </p>
                                                                        <p className='d-flex justify-content-start'>
                                                                            <LabelText displayText={`${formatDate(resources[0].validFrom)} - ${formatDate(resources[0].validTo)}`} />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr />
                                </CardBody>
                            );
                        })}
                </Card>}
            </Col>
        </Row>
    );
};
